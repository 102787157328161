<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-container class="cts-main-container cts-main-view col-12 pa-0">
        <v-col class="ma-0 pa-0 " cols="12">
            <v-row class="ma-0" dense>
                <v-col class=" mx-auto cts-view-header" cols="11" lg="10" md="11" sm="11" xl="10">
                    <v-row dense>
                        <v-col cols="12" lg="10" md="10" sm="10" xl="10">
                            <view-title
                              :icon="const_icons.TRANSACTIONS"
                              :subtitle="$vuetify.lang.t('$vuetify.transactionsDescription')"
                              :title="$vuetify.lang.t('$vuetify.transactionsTitle')"
                              idName="transactions"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 justify-center" dense>
                <v-col class="white" cols="11" lg="10" md="11" xl="10">
                    <v-row class="cts-row-processes-filters py-4 justify-space-between px-2 hidden-lg-and-up" dense>
                        <v-col :cols="view_loaded && availableTransactions.length !== 0 ? '6' : '12'" class="px-1 pb-2">
                            <v-row class="ma-0 justify-end" dense>
                                <v-text-field v-model="search" :append-icon="const_icons.SEARCH"
                                              :aria-label="$vuetify.lang.t('$vuetify.fastSearch')"
                                              :placeholder="$vuetify.lang.t('$vuetify.fastSearch')"
                                              class="ma-0 cts-text-box"
                                              clearable
                                              dense
                                              multiple
                                              outlined
                                              single-line
                                >
                                </v-text-field>
                            </v-row>
                        </v-col>
                        <v-col class="px-1 pb-2" cols="6">

                            <v-autocomplete v-if="view_loaded && availableTransactions.length !== 0"
                                            v-model="transaction_state_filter_value"
                                            :aria-label="$vuetify.lang.t('$vuetify.transactionsStatusFilter')"
                                            :items="availableTransactions"
                                            :menu-props="selectsProps"
                                            :placeholder="$vuetify.lang.t('$vuetify.transactionsStatusFilter')"
                                            class="ma-0 cts-select-box cts-box-filter-tooltip"
                                            clearable
                                            dense
                                            item-text="state"
                                            item-value="code"
                                            outlined
                                            single-line
                                            @change="resetPage()"
                            />

                        </v-col>

                        <v-col cols="12">
                            <v-row class="ma-0 justify-start" dense>
                                <a class="cts-link pt-2" @click="changeStateFiltersOpen()">
                    <span v-if="filters_open">
                      <v-icon
                        class="mr-2"
                        color="var(--primary-alternative)"
                        small
                      >
                        search_off
                      </v-icon>
                      {{$vuetify.lang.t('$vuetify.hideFilters')}} 
                    </span>
                                    <span v-else>
                      <v-icon
                        class="mr-2"
                        color="var(--primary-alternative)"
                        small
                      >
                        search
                      </v-icon>
                        {{$vuetify.lang.t('$vuetify.advancedFilters')}} 
                      </span>
                                </a>
                            </v-row>
                        </v-col>
                        <v-expand-transition>
                            <v-col v-if="filters_open" class="pa-0 pt-2" cols="12">
                                <v-row class="ma-0" dense>
                                    <v-col class="px-0" cols="12">
                                        <v-menu
                                          v-model="filter_date_model_mobile"
                                          :close-on-content-click="false"
                                          :min-width="300"
                                          :nudge-width="200"
                                          offset-y>
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                  :placeholder="dateRangeText"
                                                  :value="dateRangeText"
                                                  aria-label="Date-range"
                                                  background-color="white"
                                                  class="ma-0 cts-w-100 cts-text-box cts-text-box-fix-placeholder cts-cursor-button"
                                                  clearable
                                                  dense
                                                  hide-details
                                                  outlined
                                                  readonly
                                                  single-line
                                                  v-on="on"
                                                  @click:clear.prevent="resetFilterDates(true)"
                                                ></v-text-field>
                                            </template>
                                            <v-card>
                                                <v-row class="pa-0 ma-0" dense>
                                                    <v-divider vertical></v-divider>
                                                    <v-col class="ma-2">
                                                        <v-date-picker
                                                          id="transactions-input-date_filter_mobile"
                                                          v-model="transaction_date_filter_value"
                                                          :allowed-dates="allowed_dates"
                                                          :header-date-format="getMonthFormat" class="mx-auto d-block"
                                                          first-day-of-week="1"
                                                          no-title
                                                          range
                                                          width="300px"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="px-0" cols="12">
                                        <v-autocomplete
                                          id="transactions-input-process-code_mobile"
                                          v-model="transaction_process_code_filter_value"
                                          :aria-label="$vuetify.lang.t('$vuetify.transactionsProcessFilter')"
                                          :items="filter_processes"
                                          :menu-props="selectsProps"
                                          :placeholder="$vuetify.lang.t('$vuetify.transactionsProcessFilter')"
                                          class="ma-0 cts-select-box cts-w-100 cts-box-filter-tooltip"
                                          clearable
                                          dense
                                          hide-details
                                          item-text="name"
                                          item-value="code"
                                          outlined
                                          single-line
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col v-if="filter_linked_groups && filter_linked_groups.length !== 1" class="px-0"
                                           cols="12">
                                        <v-autocomplete
                                          v-model="transaction_linked_groups_filter_value"
                                          :aria-label="$vuetify.lang.t('$vuetify.transactionsLinkedGroupsFilter')"
                                          :items="filter_linked_groups"
                                          :menu-props="selectsProps"
                                          :placeholder="$vuetify.lang.t('$vuetify.transactionsLinkedGroupsFilter')"
                                          class="ma-0 cts-select-box cts-w-100 cts-box-filter-tooltip"
                                          clearable
                                          dense
                                          hide-details
                                          item-text="name"
                                          item-value="id"
                                          outlined
                                          single-line
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 pt-2" dense>
                                    <v-btn block class="cts-button-standard-block " small @click="getTransactions">
                                        {{$vuetify.lang.t('$vuetify.searchs')}}
                                        <v-icon class="float-right" small>{{const_icons.SEARCH}}</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-expand-transition>
                    </v-row>
                    <v-row class="cts-row-processes-filters py-4 justify-space-between px-2 hidden-md-and-down" dense>

                        <v-col class="pa-0" cols="12" md="10">
                            <v-row class="ma-0" dense>
                                <v-col class="px-0 pr-md-2" cols="12" md="2" sm="12">
                                    <v-menu
                                      v-model="filter_date_model"
                                      :close-on-content-click="false"
                                      :min-width="300"
                                      :nudge-width="200"
                                      offset-y
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                              :placeholder="dateRangeText"
                                              :value="dateRangeText"
                                              aria-label="Date-range"
                                              background-color="white"
                                              class="ma-0 cts-w-100 cts-text-box cts-text-box-fix-placeholder cts-cursor-button"
                                              clearable
                                              dense
                                              hide-details
                                              outlined
                                              readonly
                                              single-line
                                              v-on="on"
                                              @click:clear.prevent="resetFilterDates(true)"
                                            />
                                        </template>
                                        <v-card>
                                            <v-row class="pa-0 ma-0" dense>
                                                <v-divider vertical></v-divider>
                                                <v-col class="ma-2">
                                                    <v-date-picker
                                                      id="transactions-input-date_filter_desktop"
                                                      v-model="transaction_date_filter_value"
                                                      :allowed-dates="allowed_dates"
                                                      :header-date-format="getMonthFormat"
                                                      class="mx-auto d-block"
                                                      first-day-of-week="1"
                                                      no-title
                                                      range
                                                      width="300px"
                                                      @change="getTransactions()"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-menu>
                                </v-col>
                                <v-col class="px-0 pr-md-2" cols="12" md="3" sm="12">
                                    <v-select
                                      id="transactions-input-process_filter"
                                      v-model="transaction_process_code_filter_value"
                                      :aria-label="$vuetify.lang.t('$vuetify.transactionsProcessFilter')"
                                      :items="filter_processes"
                                      :menu-props="selectsProps"
                                      :placeholder="$vuetify.lang.t('$vuetify.transactionsProcessFilter')"
                                      class="ma-0 cts-select-box cts-w-100 cts-box-filter-tooltip"
                                      clearable
                                      dense
                                      hide-details
                                      item-text="name"
                                      item-value="code"
                                      outlined
                                      single-line
                                      @change="getTransactions()"
                                    >
                                        <template v-slot:item="{ item }">
                                            <v-tooltip v-if="item.name.length > 50" bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-list-item-content class="cts-break-word" v-bind="attrs"
                                                                         v-on="on">
                                                        <v-list-item-title>
                                                            <div>{{item.name}}</div>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                                <div>{{item.name}}</div>
                                            </v-tooltip>
                                            <v-list-item-content v-else>
                                                <v-list-item-title>
                                                    {{item.name}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <div v-if="item.name.length > 20" class="cts-w-100">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div class="cts-break-word" v-on="on">{{item.name}}</div>
                                                    </template>
                                                    {{item.name}}
                                                </v-tooltip>
                                            </div>
                                            <div v-else> {{item.name}}</div>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col
                                  v-if="filter_linked_groups && filter_linked_groups.length !== 1"
                                  class="px-0 pr-md-2"
                                  cols="12"
                                  md="3"
                                  sm="12"
                                >
                                    <v-select
                                      id="transactions-input-group_filter"
                                      v-model="transaction_linked_groups_filter_value"
                                      :aria-label="$vuetify.lang.t('$vuetify.transactionsLinkedGroupsFilter')"
                                      :items="filter_linked_groups"
                                      :menu-props="selectsProps"
                                      :placeholder="$vuetify.lang.t('$vuetify.transactionsLinkedGroupsFilter')"
                                      class="ma-0 cts-select-box cts-w-100 cts-box-filter-tooltip"
                                      clearable
                                      dense
                                      hide-details
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      single-line
                                      @change="getTransactions()"
                                    >
                                        <template v-slot:selection="{ item }">
                                            <v-tooltip v-if="item.name.length > 20" bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span class="cts-break-word" v-bind="attrs" v-on="on">{{
                                                            item.name
                                                        }}</span>
                                                </template>
                                                <span>{{item.name}}</span>
                                            </v-tooltip>
                                            <span v-else>{{item.name}}</span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-tooltip v-if="item.name.length > 41" bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-list-item-content class="cts-break-word" v-bind="attrs"
                                                                         v-on="on">
                                                        <v-list-item-title>
                                                            {{item.name}}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                                <span>{{item.name}}</span>
                                            </v-tooltip>
                                            <v-list-item-content v-else>
                                                <v-list-item-title>
                                                    {{item.name}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col v-if="view_loaded && availableTransactions.length !== 0" class="px-0 pr-md-2"
                                       cols="12" md="3"
                                       sm="12">
                                    <v-select
                                      id="transactions-input-state_filter" v-model="transaction_state_filter_value"
                                      :aria-label="$vuetify.lang.t('$vuetify.transactionsStatusFilter')"
                                      :items="availableTransactions"
                                      :menu-props="selectsProps"
                                      :placeholder="$vuetify.lang.t('$vuetify.transactionsStatusFilter')"
                                      class="ma-0 cts-select-box cts-box-filter-tooltip"
                                      clearable
                                      dense
                                      item-text="state"
                                      item-value="code"
                                      outlined
                                      single-line
                                      @change="resetPage()"
                                    >
                                        <template v-slot:selection="{ item }">
                                            <v-tooltip v-if="item.state.length > 17 && windowWidth < 1760" bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span class="cts-break-word" v-bind="attrs" v-on="on">{{
                                                            item.state
                                                        }}</span>
                                                </template>
                                                <span>{{item.state}}</span>
                                            </v-tooltip>
                                            <div v-else> {{item.state}}</div>
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="px-0" cols="12" md="2">
                            <v-row class="ma-0 justify-end" dense>
                                <v-text-field
                                  id="transactions-input-search"
                                  v-model="search"
                                  :append-icon="const_icons.SEARCH"
                                  :aria-label="$vuetify.lang.t('$vuetify.search')"
                                  :placeholder="$vuetify.lang.t('$vuetify.search')"
                                  class="ma-0 cts-text-box"
                                  clearable
                                  dense
                                  multiple
                                  outlined
                                  single-line
                                  @click:clear="getTransactions()"
                                >
                                </v-text-field>
                            </v-row>
                        </v-col>

                    </v-row>
                </v-col>
            </v-row>

            <v-row class="justify-center" dense>
                <v-col v-if="active_filters && !filters_open" class="pl-2 pb-2 cts-font-size-4 cts-color-tertiary"
                       cols="11" lg="10" md="11" xl="10"
                       xs="11">
                    <a id="transactions-button-search_again" :disabled="countDown === 0"
                       class="cts-link font-weight-bold mr-2"
                       @click="refreshTransactions()">
                        <v-icon id="transactions-button-search_again_icon" class="mr-1 pb-1  font-weight-bold"
                                color="var(--primary-alternative)"
                                small>refresh
                        </v-icon>
                        <span id="transactions-button-search_again_text"
                              class="cts-font-size-4">{{$vuetify.lang.t('$vuetify.searchAgain')}}</span>
                    </a>
                    <a id="transactions-button-reset_filters"
                       class="cts-link hidden-md-and-up font-weight-bold float-right"
                       @click="resetFilters()">
                <span v-if="active_filters"
                      id="transactions-button-reset_filters_text">{{$vuetify.lang.t('$vuetify.restartFilters')}}
                  <v-icon class="ml-2" color="var(--primary-alternative)" small>cached</v-icon>
                </span>
                    </a>

                    <span id="transactions-text-active_filters"
                          class="mr-4">{{$vuetify.lang.t('$vuetify.activeFilters')}}:</span>

                    <span v-if="transaction_date_filter_value" id="transactions-text-active_filters_date" class="mx-1">{{$vuetify.lang.t('$vuetify.date')}}:
              <span id="transactions-text-active_filters_date_value"
                    class="font-weight-medium"> {{dateRangeText}}</span>
            </span>

                    <span v-if="transaction_process_code_filter_value" id="transactions-text-active_filters_process"
                          class="mx-1">
              | {{$vuetify.lang.t('$vuetify.process')}}:
              <span id="transactions-text-active_filters_process_value"
                    class="font-weight-medium"> {{filter_processes.find(p => p.code === transaction_process_code_filter_value).name}}</span>
            </span>

                    <span v-if="transaction_state_filter_value" id="transactions-text-active_filters_group"
                          class="mx-1">| {{$vuetify.lang.t('$vuetify.state')}}:
              <span id="transactions-text-active_filters_group_value"
                    class="font-weight-medium"> {{transaction_state_filter_value}}</span>
            </span>
                    <span v-if="transaction_linked_groups_filter_value"
                          id="transactions-text-active_filters_linked_group" class="mx-1">
              | {{$vuetify.lang.t('$vuetify.group')}}:
              <span id="transactions-text-active_filters_linked_group_value"
                    class="font-weight-medium"> {{filter_linked_groups.find(lg => lg.id === transaction_linked_groups_filter_value).name}}</span>
            </span>

                    <a id="transactions-button-reset_filters" class="cts-link d-none d-md-block float-right"
                       @click="resetFilters()">
              <span v-if="active_filters"
                    id="transactions-button-reset_filters_text">{{$vuetify.lang.t('$vuetify.restartFilters')}}
                <v-icon class="ml-2" color="var(--primary-alternative)" small>cached</v-icon>
              </span>
                    </a>
                </v-col>
                <v-col v-if="availableTransactions" class="pt-0" cols="11" lg="10" md="11" xl="10" xs="11">
                    <v-data-table
                      id="transactions-table-transactions_table"
                      :footer-props="{'items-per-page-options':[5, 10, 15, 50]}"
                      :headers="headers"
                      :items="availableTransactions"
                      :options.sync="options"
                      :search="search"
                      :sort-by.sync="sortBy"
                      calculate-widths
                      class="elevation-0 col-12 cts-transactions-datatable px-0 pt-0"
                      dense
                      sort-desc
                    >
                        <template v-slot:item="{item}">
                            <transaction-list-row-desktop :item="item" @clicked="rowClick"/>
                            <transactions-list-row-mobile :item="item" @clicked="rowClick"/>
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <v-col v-if="!availableTransactions" class="ma-0 pa-0" cols="12">
                <loading-container/>
            </v-col>
        </v-col>
    </v-container>
</template>

<script>
/* eslint-disable */
import const_icons from '@/constants/icons'
import ViewTitle from "@/components/structures/viewTitle"
import const_global from "@/constants/global"
import {
    NAMESPACE as TRANSACTIONS_NAMESPACE,
    ACTIONS as TRANSACTIONS_ACTIONS,
    STATE as TRANSACTIONS_STATE
} from "@/constants/vuex/transactions"
import const_permissions from "@/constants/permissions"
import LoadingContainer from "@/components/structures/loadingContainer";
import TransactionsListRowMobile from "@/components/sections/transactions/transactionsListRowMobile";
import TransactionListRowDesktop from "@/components/sections/transactions/transactionListRowDesktop";
import {getTransactions, getTransactionsSearchFilters} from "@/services/transactionServices"
import {redirect} from "@/util/router_utils"
import {capitalize, allowed_dates_calendar} from "@/util/utils"
import {ERROR_500, TRANSACTION_VIEW} from "@/constants/router/routes"
import dayjs from "dayjs";
import {NAMESPACE as LOCATION_NAMESPACE, GETTERS as LOCATION_GETTERS} from "@/constants/vuex/location"
import {mapGetters} from "vuex";


export default {
    name: "Transactions",
    components: {
        TransactionListRowDesktop,
        TransactionsListRowMobile, LoadingContainer, ViewTitle
    },
    data() {
        return {
            const_icons,
            const_global,
            const_permissions,
            transactions: null,
            filter_date_model: false,
            filter_date_model_mobile: false,
            transaction_date_filter_value: [],
            transaction_process_code_filter_value: null,
            transaction_linked_groups_filter_value: null,
            transaction_state_filter_value: null,
            sortBy: 'created_at',
            lastWeekDateFilter: null,
            countDown: 0,
            active_filters: false,
            filters_open: false,
            windowWidth: window.innerWidth,
            headers: [
                {
                    text: this.$vuetify.lang.t('$vuetify.process'),
                    align: 'start',
                    value: 'id',
                    sortable: false,
                    class: "hidden-md-and-down"
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.transactionId'),
                    align: 'start',
                    value: 'process_name',
                    sortable: false,
                    class: "hidden-md-and-down hidden-md-and-up"
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.createdDate'),
                    align: 'start',
                    value: 'created_at',
                    class: "hidden-md-and-down"
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.transactionInterveners'), align: 'start', value: 'contact_id',
                    class: "hidden-md-and-down"
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.state'),
                    align: 'start',
                    value: 'state',
                    filter: this.filterState,
                    class: "hidden-md-and-down"
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.certificate'),
                    align: 'center',
                    value: 'actions',
                    sortable: false,
                    class: "hidden-md-and-down"
                },
            ],
            options: {},
            searchText: "",
            actual_filter: "",
            filter_processes: [],
            filter_linked_groups: [],
            selectsProps: {
                "closeOnClick": false,
                "closeOnContentClick": true,
                "openOnClick": false,
                "maxHeight": 300
            },
            dates: [],
            params_info: null
        }
    },
    computed: {
        ...mapGetters({
            currentLocation: `${LOCATION_NAMESPACE}/${LOCATION_GETTERS.G_CURRENT_LOCATION}`,
        }),
        search: {
            set(val) {
                this.searchText = val ? val.normalize('NFD').replace(/[\u0300-\u036f]/g, "") : ""
            },
            get() {
                return this.searchText
            }
        },
        view_loaded() {
            return this.availableTransactions != null
        },
        dateRangeText() {
            let result = []

            this.transaction_date_filter_value.forEach(d => {
                result.push(d.substr(8, 2) + "/" + d.substr(5, 2))
            })
            return result.join(' ~ ')
        },
        availableTransactions() {
            let transactions = this.transactions

            let format_transactions = null
            if (transactions) {
                format_transactions = []

                transactions.forEach(transaction => {
                    let transaction_formatted = {
                        process_image: transaction.process_image,
                        process_name: transaction.translated_process_name,
                        transaction_name: !transaction.transaction_name || transaction.process_name === transaction.transaction_name ? transaction.translated_process_name : transaction.transaction_name,
                        id: transaction.id,
                        // contact_id: transaction.recipient_data.contact_id.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                        contact_id: transaction.recipient_data?.contact_id,
                        contact_complete_name: (transaction.recipient_data?.contact_name + " " + transaction.recipient_data?.contact_surname).normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
                        created_at: transaction.created_at,
                        updated_at: transaction.updated_at,
                        state: capitalize(transaction.state),
                        certification: transaction.certification,
                        interveners: transaction.interveners
                    }
                    format_transactions.push(transaction_formatted)
                })
            }
            return format_transactions
        }
    },

    watch: {
        transaction_date_filter_value(val) {
            if (val.length === 2) {
                this.filter_date_model_mobile = false
            }
        },

        transaction_state_filter_value(val) {
            this.active_filters = !!val
        }
    },

    created() {
        // this.resetFilters()
        this.$store.dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_AVAILABLE_TRANSACTIONS}`, [])
        let active_filters = this.$store.state[TRANSACTIONS_NAMESPACE][TRANSACTIONS_STATE.S_ACTIVE_FILTERS]

        getTransactionsSearchFilters().then(response => {
            this.filter_processes = response.data["processes"]
            this.filter_linked_groups = response.data["linked_groups"]

            if (active_filters !== null) {

                if (active_filters["linked_groups"] != null) {
                    this.transaction_linked_groups_filter_value = active_filters["linked_groups"]
                }
                if (active_filters["process_code"] != null) {
                    this.transaction_process_code_filter_value = active_filters["process_code"]
                }
                if (active_filters["creation_timestamp"] != null) {
                    this.transaction_date_filter_value = active_filters["creation_timestamp"]
                }
            } else {
                this.resetFilterDates(false)
            }
            this.checkRouteParams()
            this.getTransactions()
        })


    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    methods: {
        async getTransactions() {
            this.transactions = null
            await this.$store.dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_TRANSACTION_SELECTED}`, null)
            this.filter_date_model = false
            this.filter_date_model_mobile = false
            this.transaction_state_filter_value = null
            this.resetPage()

            const store_filters = {
                linked_groups: this.transaction_linked_groups_filter_value,
                process_code: this.transaction_process_code_filter_value,
                creation_timestamp: this.transaction_date_filter_value
            }

            await this.$store.dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_ACTIVE_FILTERS}`, store_filters)

            const getTransactionsResponse = await getTransactions(store_filters)
            if (getTransactionsResponse.data && Array.isArray(getTransactionsResponse.data)) {
                this.transactions = getTransactionsResponse.data
                this.active_filters = true
                this.filters_open = false

            } else {
                redirect(ERROR_500)
            }

        },

        allowed_dates(val) {
            return allowed_dates_calendar(this.transaction_date_filter_value, val)
        },
        refreshTransactions() {
            let self = this
            this.getTransactions()
            self.countDown = 5
            let intervalTransactions = setInterval(function () {

                self.countDown = self.countDown - 1
                if (self.countDown <= 0) {
                    clearInterval(intervalTransactions);
                }

            }, 1000);
        },
        rowClick(id) {
            this.openTransaction(id)
        },
        openTransaction(transaction_id) {
            this.$store.dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_TRANSACTION_SELECTED}`, transaction_id)
            redirect(TRANSACTION_VIEW)
        },
        changeStateFiltersOpen() {
            this.filters_open = !this.filters_open
        },
        filterState(value) {
            if (!this.transaction_state_filter_value) {
                return true;
            }
            return value === this.transaction_state_filter_value;

        },
        isLastWeekDateFilter(current_filter) {
            return JSON.stringify(current_filter) === JSON.stringify(this.lastWeekDateFilter);

        },
        resetFilterDates(reload) {
            this.transaction_date_filter_value = []
            let date = new Date()
            date.setDate(date.getDate() - 7);
            this.transaction_date_filter_value.push(date.getFullYear() + "-" + ((date.getMonth() + 1).toString().padStart(2, "0")) + "-" + date.getDate().toString().padStart(2, "0"))
            date.setDate(date.getDate() + 7);
            this.transaction_date_filter_value.push(date.getFullYear() + "-" + ((date.getMonth() + 1).toString().padStart(2, "0")) + "-" + date.getDate().toString().padStart(2, "0"))

            this.lastWeekDateFilter = this.transaction_date_filter_value
            if (reload) {
                this.getTransactions()
            }

        },

        resetFilters() {
            this.$store.dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_ACTIVE_FILTERS}`, null)
            this.active_filters = false
            this.transaction_process_code_filter_value = null
            this.transaction_linked_groups_filter_value = null
            this.transaction_state_filter_value = null
            this.search = ""
            this.resetFilterDates(true)
        },
        checkRouteParams() {
            this.params_info = this.$route.params

            if (Object.keys(this.params_info).length !== 0) {

                if (this.params_info.process_code) {
                    this.transaction_process_code_filter_value = this.params_info.process_code
                    this.$route.query.process_code = this.params_info.process_code
                }
                if (this.params_info.group_id) {
                    this.transaction_linked_groups_filter_value = this.params_info.group_id
                }
            } else {
                this.params_info = false
            }
        },

        resetPage() {
            this.options.page = 1
        },

        onResize() {
            this.windowWidth = window.innerWidth
        },

        getMonthFormat(e) {
            let date = new Date(e.split("-")[0], e.split("-")[1] - 1, 5)

            const res = dayjs(date).locale(this.currentLocation.code).format("MMMM YYYY").toLowerCase()
            return res === "invalid date" ? "" : res
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }
}
</script>
