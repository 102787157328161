<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-stepper v-model="currentStep" class="ma-0 pa-0" elevation="0" max-width="100%">
      <v-stepper-header class="stepper-header justify-center">
        <v-stepper-step
          v-for="(step) in steps"
          :key="step.id"
          :step="step.id"
          class="ma-0 pa-0 col col-3"
        >
          <div class="d-flex flex-row">
            <v-btn v-if="step.id !== 1 && step.id === currentStep"
               class="my-auto"
               color="var(--primary-alternative)"
               icon
               @click="prevStep"
            >
              <v-icon size="50">
                keyboard_arrow_left
              </v-icon>
            </v-btn>

            <step-card
              :icon="step.icon"
              :status="getCardStatus(step.id)"
              :subtitle="step.description"
              :title="step.title"
            />
            <v-btn
              v-if="step.id !== steps.length && step.id === currentStep"
              :color="step.id === currentStep ? 'var(--primary-alternative)' : 'var(--ternary)'"
              :disabled="step.id !== currentStep"
              class="my-auto"
              icon
              @click="nextStep">
              <v-icon size="50">
                keyboard_arrow_right
              </v-icon>
            </v-btn>
          </div>
        </v-stepper-step>

      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          v-for="step in steps"
          :key="step.id"
          :step="step.id"
        >
          <component
            :is="step.component"
            :documents="documents"
            :global_transaction="transaction"
            :languages="metadata.languages"
            :transaction_defaults="JSON.parse(metadata.behaviorLayer).steps[0].mapping"
            @validConfigurationForm="handleValidConfigurationForm"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-row :justify="currentStep !== 1 ? 'space-between' : 'end'" class="steppers-controls" dense>
      <v-btn v-if="currentStep !== 1" class="prev-button" @click="prevStep()">
        Volver
      </v-btn>

      <v-btn
        v-if="currentStep !== steps.length"
        :disabled="checkNextButtonDisabled"
        class="next-button"
        @click="nextStep()"
      >
        Continuar
      </v-btn>

      <v-btn
        v-if="currentStep === steps.length"
        :disabled="checkNextButtonDisabled"
        class="next-button"
        @click="sendTransaction()"
      >
        Enviar
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import StepCard from "@/components/structures/step_launcher/stepCard.vue";
import {
  ACTIONS,
  GETTERS as PROCESSES_GETTERS,
  NAMESPACE as PROCESSES_NAMESPACE,
  STATE as PROCESSES_STATE
} from "@/constants/vuex/processes";
import { refreshSession } from "@/services/userServices";
// import transactionDefaults from "@/constants/dataExamples/transaction_default-digitalSignature.json";
import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE } from "@/constants/vuex/authentication";
import {
  buildCommunications,
  buildInterveners,
  buildSettings,
  buildSignatures
} from "@/util/digitalSignatureConversor";
import { launchTransaction } from "@/services/processServices";

export default {
  name: "ProcessDigitalSignatureModel",
  components: {
    StepCard,
    DigitalSignatureConfigurationStep: () => import('@/components/sections/processLaunch/models/digitalSignature/steps/DigitalSignatureConfigurationStep'),
    DigitalSignatureSignersStep: () => import('@/components/sections/processLaunch/models/digitalSignature/steps/DigitalSignatureSignersStep'),
    DigitalSignatureDocumentsStep: () => import('@/components/sections/processLaunch/models/digitalSignature/steps/DigitalSignatureDocumentsStep'),
    DigitalSignaturePreviewStep: () => import('@/components/sections/processLaunch/models/digitalSignature/steps/DigitalSignaturePreviewStep'),
  },
  props: {
    processFields: Object,
    extra: Object,
    mapping: Object,
    metadata: Object
  },

  data: () => {
    return {
      currentStep: 1,
      steps: [
        {
          id: 1,
          title: 'Configuración',
          description: 'Personalización de la ceremonia de firma',
          icon: 'settings',
          component: 'DigitalSignatureConfigurationStep'
        },
        {
          id: 2,
          title: 'Firmantes',
          description: 'Configuración de los firmantes y experiencia de firma',
          icon: 'people',
          component: 'DigitalSignatureSignersStep'
        },
        {
          id: 3,
          title: 'Documentos y firmas',
          description: 'Selección de documentos e interacción de los firmantes',
          icon: 'description',
          component: 'DigitalSignatureDocumentsStep'
        },
        {
          id: 4,
          title: 'Envío',
          description: 'Confirmación y lanzamiento de la ceremonia de firma',
          icon: 'send',
          component: 'DigitalSignaturePreviewStep'
        },
      ],
      //transaction_default-digitalSignature.json
      // transaction_defaults: this.metadata.behaviorLayer,
      transaction: {
        name: '',
        type: 'remote',
        expiration: 30,
        reminders: [],
        global_message: '',
        interveners: [],
        orderEnabled: false, /*Sacar boolean*/
        signatures: [],
      },
      documents: [],
      validConfigurationForm: false
    }
  },
  computed: {
    checkNextButtonDisabled() {
      return this.currentStep === 2 && this.transaction.interveners.length === 0 || !this.validConfigurationForm
    }
  },

  mounted() {
    // Recuperar el estado cuando el componente se monta
    const transactionPending = this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_TRANSACTION_PENDING}`]
    const transactionStep = this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_TRANSACTION_STEP}`]

    if (transactionPending) {
      this.transaction = transactionPending;
      if (transactionStep) {
        this.currentStep = parseInt(transactionStep, 10);
      }
    } else {
      this.setDefaultValuesForTransaction()
    }
    //window.addEventListener('beforeunload', this.handleBeforeUnload);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    this.$store.dispatch(PROCESSES_NAMESPACE + "/" + ACTIONS.A_SET_TRANSACTION_STEP, 0)
    this.$store.dispatch(PROCESSES_NAMESPACE + "/" + ACTIONS.A_SET_TRANSACTION_PENDING, null)
  },

  watch: {
    currentStep(newVal) {
      this.$store.dispatch(PROCESSES_NAMESPACE + "/" + ACTIONS.A_SET_TRANSACTION_STEP, newVal)
    },

    transaction: {
      handler(newVal) {
        this.$store.dispatch(PROCESSES_NAMESPACE + "/" + ACTIONS.A_SET_TRANSACTION_PENDING, newVal)
      },
      deep: true
    },

    documents(value) {
      console.log("Documentos", value)
    }
  },

  methods: {
    handleBeforeUnload(e) {
      const message = 'Si abandona ahora el proceso, perderá los datos introducidos. ¿Está seguro de que desea salir?';
      e.returnValue = message;

      return message;
    },

    setDefaultValuesForTransaction() {
      const mapping = JSON.parse(this.metadata.behaviorLayer).steps[0].mapping
      for (const fieldKey  of Object.keys(mapping)) {
        this.transaction[fieldKey] = mapping[fieldKey].default_value;
      }
    },

    getCardStatus(index) {
      if (index < this.currentStep) return 'success';
      if (index === this.currentStep) return 'active';

      return 'disable';
    },

    nextStep() {
      if (this.currentStep <= this.steps.length - 1) {
        this.currentStep++
        refreshSession()
      }
    },

    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--
        refreshSession()
      }
    },

    handleValidConfigurationForm(isValid) {
      this.validConfigurationForm = isValid
    },

    sendTransaction() {
      const billingUnit = this.selectedBillingUnit != null ? this.selectedBillingUnit.code : ""
      const fields = {
        "master_account_code": this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_MASTER_ACCOUNT_CODE],
        "process_code": this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_CODE],
        "cost_center": billingUnit
      }

      const payload = {
        // recipients: [{ name: "alberto" }],
        parameters: fields,
      }

      console.log("transaction:", this.transaction)
      console.log("ENTRADA:", payload)

      const csApiInterveners = buildInterveners(
        this.transaction.interveners,
        this.transaction.contactChannel,
        this.transaction.orderEnabled
      );

      const csApiCommunications = buildCommunications(
        this.transaction.reminders,
        this.transaction.interveners
      );

      const csApiFrontRendererSettings = buildSettings(
        this.transaction.interveners,
        this.transaction.contactChannel
      );

      const documents = this.documents.map(doc => {
        return {
          file_name: doc.name,
          file_content: doc.file.split(",")[1]
        }
      });

      const csApiSignatures = buildSignatures(this.transaction.signatures);

      // payload.parameters.transaction_name = this.transaction.name;
      payload.parameters.expiration = this.transaction.expiration;
      payload.parameters.communications_email = csApiCommunications.communication_email_CS;
      payload.parameters.communications_SMS = csApiCommunications.communication_SMS_CS;
      payload.parameters.interveners = csApiInterveners.interveners;
      payload.parameters.signature_settings = csApiSignatures;
      payload.parameters.front_renderer_settings = csApiFrontRendererSettings;
      payload.parameters.documents = documents;
      payload.parameters.tags_SMS = csApiInterveners.tags_SMS;
      payload.parameters.tags_email = csApiInterveners.tags_email;
      payload.parameters.signature_type = this.transaction.type;



      console.log("PAYLOAD:", payload)

      launchTransaction(payload, true)
    }

  }
}
</script>

<style lang="scss">
  .stepper-header {
    justify-content: center;
    box-shadow: none;
    border-bottom: 1px solid var(--card-border);
    height: auto;

    .v-stepper__step {
      padding: 0;
      max-width: 360px;
    }

    .v-stepper__step__step {
      display: none !important;
    }
  }

  .v-stepper__step__step {
    display: none;
  }

  .v-stepper__items {
    min-height: 50vh;
  }

  .steppers-controls {
    border-top: 1px solid var(--card-border);
    padding: 12px;

    .next-button {
      background-color: var(--primary-alternative) !important;
      color: var(--clear);
      width: 240px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14) !important;

    }

    .next-button:hover {
      background-blend-mode: lighten !important;
    }

    .prev-button {
      background-color: var(--tertiary) !important;
      color: var(--clear);
      width: 240px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14) !important;

    }

    .prev-button:hover {
      background-blend-mode: lighten !important;
    }
  }
</style>