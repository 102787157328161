import { render, staticRenderFns } from "./itemsCard.vue?vue&type=template&id=d1ca8690&scoped=true"
import script from "./itemsCard.vue?vue&type=script&lang=js"
export * from "./itemsCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1ca8690",
  null
  
)

export default component.exports