<template>
    <v-row id="header-user-selector" dense>
        <v-col class="cts-context-menu">
            <v-menu v-model="open" :close-on-content-click="false" :nudge-top="-10" offset-y
                    transition="slide-y-transition">
                <template v-slot:activator="{ on }">
                    <v-btn
                      id="user-menu-btn"
                      class="elevation-0 cts-user-btn mx-3 float-right"
                      fab
                      v-on="on"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="cts-color-clear" v-bind="attrs" v-on="on">{{initials}}</span>
                            </template>
                            <span>{{$vuetify.lang.t('$vuetify.account')}}</span>
                        </v-tooltip>
                    </v-btn>
                </template>
                <v-card v-if="authObject" id="user-selector-submenu"
                        class="px-2 py-1 cts-usermenu-card cts-bgcolor-clear" color="var(--user-menu-background)"
                        width="240">
                    <v-card-title id="user-selector-submenu-title" class="cts-usermenu-name px-2 pt-2 pb-1">
                        <h3 id="user-selector-submenu-title_name" class="col-lg-12 ma-0 pa-0">
                            {{authObject[const_global.NAME]}}</h3>
                        <small id="user-selector-submenu-title_type">{{groupNameReactive}}</small>
                    </v-card-title>

                    <h5 v-if="hasSubaccount" id="user-selector-submenu-selected_nosubaccount_text"
                        class="cts-color-tertiary">{{$vuetify.lang.t('$vuetify.selectedSubaccount')}}:</h5>
                    <h5 v-if="!hasSubaccount" id="user-selector-submenu-selected_subaccount_text"
                        class="cts-color-tertiary">{{$vuetify.lang.t('$vuetify.withoutSubaccount')}}</h5>
                    <subaccount-selector v-if="hasSubaccount" id="user-selector-submenu-subaccounts"
                                         :subaccount_selected="authObject[const_global.ACTIVE_SUBACCOUNT]"
                                         :subaccounts="authObject[const_global.GROUP][const_global.GROUP_SUBACCOUNTS]"
                                         role="menuitem" @closeUserMenu="closeMenu"/>

                    <div
                      v-if="checkPermission(const_permissions.SECTION_ADMINISTRATION) || checkPermission(const_permissions.SECTION_ACCOUNT)">
                        <hr v-if="hasSubaccount" class="mb-2">
                        <v-row class="mb-2 mt-0 mx-0 justify-space-between" dense>
                            <v-btn v-if="checkPermission(const_permissions.SECTION_ACCOUNT)"
                                   id="user-menu-manage-account-btn" class="col-12 text-center cts-bgcolor-clear"
                                   outlined role="menuitem"
                                   small @click="openSection(ACCOUNT, 'details')">
                                {{$vuetify.lang.t('$vuetify.account')}}
                                <v-icon class="float-right" color="var(--primary)" small>{{const_icons.USER}}</v-icon>
                            </v-btn>
                        </v-row>
                        <v-row v-if="checkPermission(const_permissions.SECTION_ADMINISTRATION)"
                               class="my-2 mt-0 mx-0 justify-space-between"
                               dense>
                            <v-btn id="user-menu-administration-btn" class="col-12 text-center cts-bgcolor-clear"
                                   outlined role="menuitem"
                                   small @click="openSection(ADMINISTRATION)">
                                {{$vuetify.lang.t('$vuetify.administration')}}
                                <v-icon class="float-right" color="var(--primary)" small>{{
                                        const_icons.ADMINISTRATION
                                    }}
                                </v-icon>
                            </v-btn>
                        </v-row>

                    </div>
                    <hr class="mb-2">
                    <v-row class="my-2 mx-0 justify-space-between" dense>
                        <v-btn id="user-menu-logout-btn" class="col-12 text-center cts-bgcolor-clear" outlined
                               role="menuitem"
                               small @click="goLogout()">
                            {{$vuetify.lang.t('$vuetify.logout')}}
                            <v-icon class="float-right" color="var(--primary)" small>{{const_icons.LOGOUT}}</v-icon>
                        </v-btn>
                    </v-row>
                    <v-row class="mt-2 mx-0 justify-center" dense>
                        <h5 class="cts-color-tertiary">{{$vuetify.lang.t('$vuetify.lastLoginSuccess')}}</h5>
                    </v-row>
                    <v-row class=" mx-0 justify-center" dense>
            <span v-if="authObject[const_global.LAST_LOGIN_SUCCESS] !== ''"
                  class="cts-color-darken font-weight-medium cts-font-size-4">
              {{dayjs.unix(authObject[const_global.LAST_LOGIN_SUCCESS]).format(const_global.DATE_TIME_FORMAT_A)}}
            </span>
                        <span v-else class="cts-color-darken font-weight-medium cts-font-size-4">
              {{$vuetify.lang.t('$vuetify.noRecord')}}
            </span>

                    </v-row>
                </v-card>
            </v-menu>
        </v-col>
        <v-dialog v-model="loginPopupVisible" max-width="400px" scrollable @click:outside="closeDialog()">
            <v-card tile>
                <v-card-title class="ma-0 pa-0">
                    <dialog-title
                      v-if="loginPopupVisible"
                      :close="true"
                      :text="$vuetify.lang.t('$vuetify.loginInfo')"
                      event="closeDialog"
                      icon="badge"
                      @closeDialog="closeDialog"
                    />
                </v-card-title>
                <v-card-text class="pt-4">
                    <v-row dense>
                        <v-col class="cts-field-medium-block" cols="12">
                            <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.lastLoginSuccess')}}</span>
                            <h4 class="cts-color-darken font-weight-medium">
                    <span v-if="authObject[const_global.LAST_LOGIN_SUCCESS] !== ''"
                          class="cts-color-darken font-weight-medium">
                      {{
                            dayjs.unix(authObject[const_global.LAST_LOGIN_SUCCESS]).format(const_global.DATE_TIME_FORMAT_A)
                        }}
                    </span>
                                <span v-else class="cts-color-darken font-weight-medium">
                      {{$vuetify.lang.t('$vuetify.noRecord')}}
                    </span>
                            </h4>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="cts-field-medium-block" cols="12">
                            <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.lastLoginError')}}</span>
                            <h4>
                  <span v-if="authObject[const_global.LAST_LOGIN_ERROR] !== ''"
                        class="cts-color-darken font-weight-medium">
                    {{dayjs.unix(authObject[const_global.LAST_LOGIN_ERROR]).format(const_global.DATE_TIME_FORMAT_A)}}
                  </span>
                                <span v-else class="cts-color-darken font-weight-medium">
                    {{$vuetify.lang.t('$vuetify.noRecord')}}
                  </span>
                            </h4>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import const_global from "@/constants/global"
import const_icons from "@/constants/icons"
import {
    NAMESPACE as AUTHENTICATION_NAMESPACE,
    STATE as AUTHENTICATION_STATE,
    ACTIONS as AUTHENTICATION_ACTIONS
} from "@/constants/vuex/authentication"
import SubaccountSelector from "./subaccountSelector"
import const_permissions from "@/constants/permissions"
import {userLogout} from "@/services/userServices"
import {redirect} from "@/util/router_utils"
import {checkPermission} from "@/util/store_utils"
import {getInitials} from "@/util/utils"
import {ACCOUNT, ADMINISTRATION, LOGIN} from "@/constants/router/routes"
import DialogTitle from '@/components/structures/dialogTitle'
import {ServerBus} from "@/main"

export default {
    name: "UserSelector",
    components: {DialogTitle, SubaccountSelector},
    props: {
        authObject: Object,
    },

    data() {
        return {
            checkPermission,
            const_icons,
            const_permissions,
            open: false,
            ACCOUNT,
            ADMINISTRATION,
            const_global
        }
    },

    computed: {
        hasSubaccount() {
            return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_SUBACCOUNT_CODE] !== ""
        },

        loginPopupVisible: {
            get() {
                return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_LOGIN_SESSION_POPUP]
            },
            set(val) {
                this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGIN_SESSION_POPUP}`, val)
            }
        },

        initials() {
            return getInitials(this.authObject[const_global.NAME])
        },

        groupNameReactive() {
            return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_NAME]
        }
    },

    methods: {
        openSection(route, section = null) {
            this.closeMenu()
            redirect(route, {section})
        },

        goLogout() {
            this.closeMenu()
            userLogout().then(() => {
                this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGOUT_DATA}`)
                this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_TOKEN_EXPIRATION_TIMESTAMP}`, null)
                ServerBus.$emit("endExpirationTimestamp")
                redirect(LOGIN)
            })
        },
        closeMenu() {
            this.open = false
        },
        closeDialog() {
            this.loginPopupVisible = false
        }
    },
}
</script>
