<template>
  <v-row dense>
    <v-col>
      <v-row dense>
                <span
                  class="text-uppercase cts-color-tertiary">{{$vuetify.lang.t('$vuetify.transactionDigitalSignatureInfo')}}</span>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="3">
          <v-list-item class="transaction-digital-card">
            <v-list-item-avatar>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Expiración</v-list-item-title>
              <v-list-item-subtitle>
                {{dayjs(expirationTimestamp).format(const_global.DATE_TIME_FORMAT_B)}}
                ({{dayjs(expirationTimestamp).fromNow()}})
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="3">
          <v-list-item class="transaction-digital-card">
            <v-list-item-avatar>
              <v-icon v-if="reminders.emails > 0 || reminders.sms > 0" color="var(--success)">mdi-alarm
              </v-icon>
              <v-icon v-else color="var(--error)">mdi-alarm-off</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{$vuetify.lang.t('$vuetify.reminders')}}</v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="reminders.emails > 0">{{reminders.emails}} email(s)</span>
                <span v-else-if="reminders.sms > 0">{{reminders.sms}} sms(s)</span>
                <span v-else>Sin configurar</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script lang="js">
import dayjs from "../../../../../plugins/dayjs";
import const_global from "@/constants/global";

export default {
  name: "TransactionSectionDigitalSignature",
  methods: {dayjs},
  props: {
    transaction_content: Object
  },
  data() {
    return {
      const_global
    }
  },
  computed: {
    expirationTimestamp() {
      const daysToExpire = this.transaction_content.parameters.find(param => param.key === "expiration").value
      return new Date().getTime() + daysToExpire * 24 * 60 * 60 * 1000
    },
    reminders() {
      const emails = this.transaction_content.parameters.find(param => param.key === "communications_email")
      const sms = this.transaction_content.parameters.find(param => param.key === "communications_sms")

      return {
        emails: emails && emails.value ? emails.value.length - 1 : [],
        sms: sms && emails.value ? sms.value.length - 1 : []
      }
    }
  }
}
</script>


<style lang="scss">
.transaction-digital-card {
  border: solid 1px var(--card-border);
  border-radius: 4px;
}
</style>