<template>
  <v-row class="intervener-transaction-card" dense justify="start">
    <v-col class="text-center pa-0" cols="1" style="
        border-right: 1px solid var(--card-border);">
      <v-list-item-avatar>
        <v-icon size="42">person_outline</v-icon>
      </v-list-item-avatar>
    </v-col>
    <v-col cols="2">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">Referencia
          </v-list-item-title>
          <v-list-item-subtitle>
            {{reference}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="3">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">Nombre completo
          </v-list-item-title>
          <v-list-item-subtitle>
            {{name}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="3">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">Contacto
          </v-list-item-title>
          <v-list-item-subtitle>
            {{contact}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col :class="signCeremony? 'pr-0':'pr-4'" cols="3">
      <v-row dense justify="end">
        <v-col cols="12" xl="5">
          <v-btn block class="button-intervener-details" color="var(--primary-alternative)" outlined small>
            <v-icon class="mr-1" size="20">visibility</v-icon>
            <span>{{$vuetify.lang.t('$vuetify.details')}}</span>
          </v-btn>

        </v-col>
        <v-col v-if="signCeremony" cols="12" xl="7">
          <v-btn block class="" color="var(--primary-alternative)"
                 dark small target="_blank"
                 @click="openConfirmDialog">
            <v-icon class="mr-2" size="20">mdi-draw-pen</v-icon>
            <span>{{$vuetify.lang.t('$vuetify.goToSignature')}}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-if="confirmDialog" v-model="confirmDialog" width="600">
      <v-card class="pa-8 pb-4">
        <v-card-text>
          <v-row class="mb-4" dense justify="center">
            <span class="cts-font-size-title font-weight-medium">{{intervener.personal_info.name}}</span>
          </v-row>
          <p class="cts-font-size-2">{{$vuetify.lang.t('$vuetify.intervenerStartCeremony')}}</p>
        </v-card-text>
        <v-card-actions>
          <v-row dense justify="center">
            <v-btn class="mr-2" color="var(--primary)" outlined @click="confirmDialog = false">
              {{$vuetify.lang.t('$vuetify.cancel')}}
            </v-btn>
            <v-btn color="var(--primary-alternative)" dark @click="openFrontRenderer()">
              {{$vuetify.lang.t('$vuetify.goToSignature')}}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "IntervenerCard",
  props: {
    intervener: Object,
    type: String,
    showAction: Boolean,
    actionLink: String,
    actionText: String
  },
  data() {
    return {
      reference: "",
      name: "",
      contact: "",
      confirmDialog: false
    }
  },
  created() {


    if (this.type === 'intervener') {
      this.reference = this.intervener.client_id
      this.name = "Intervener"
      this.contact = this.intervener.contact_info.email[0]
    } else {
      this.reference = this.intervener.contact_id
      this.name = this.intervener.contact_name + ' ' + this.intervener.contact_surname
      if (this.intervener.contact_email !== "") {
        this.contact = this.intervener.contact_email
      } else if (this.intervener.contact_phone !== "") {
        this.contact = this.intervener.contact_phone
      } else {
        this.contact = "No especificado"
      }
    }
  },
  computed: {
    signCeremony() {
      return !this.showAction && this.actionLink
    }
  },
  methods: {
    openConfirmDialog() {
      this.confirmDialog = true
    },
    openFrontRenderer() {
      window.open(this.actionLink, '_blank')
      this.confirmDialog = false
    }
  }
}
</script>

<style lang="scss">
.transaction-details-container {
  padding-left: 14px !important;

}

.transaction-parameters {
  border-left: 1px solid var(--card-border);

  span {
    font-size: 0.9rem;
  }
}

.intervener-transaction-card {
  border: solid 1px var(--card-border);
  min-height: 60px;
  align-content: space-evenly;
  border-radius: 4px;
  margin-bottom: 8px;

  .col {
    height: fit-content;
    margin: auto 0;
  }

  .v-btn {
  }

  .v-list-item__title, .v-list-item__subtitle {
    font-size: 0.9rem;
  }


  .icon {
    min-width: 50px !important;


  }

  .v-list-item__avatar {
    margin-right: 0 !important;
  }

  .v-list-item {

    padding: 0;

    .v-list-item__content {
      padding: 0;
      padding-left: 8px;
    }
  }

  .button-intervener-details {
    border: solid 1px var(--primary-alternative);
  }
}
</style>