import VueRouter from 'vue-router'
import routes from './routes'
import store from "../store"
import const_global from "@/constants/global"
import {checkPermission} from "@/util/store_utils"
import {
  GETTERS as AUTHENTICATION_GETTERS,
  NAMESPACE as AUTHENTICATION_NAMESPACE,
} from "@/constants/vuex/authentication"
import Vue from "vue"
import {ACTIVATE_USER, FORBIDDEN, LOGIN, NEW_PASSWORD, ROOT, WITHOUT_SUBACCOUNTS} from "@/constants/router/routes"
import {userLogout} from '@/services/userServices'

const router = new VueRouter({
    mode: 'history',
    //mode: 'hash',
    base: "/",
    routes: routes,

    scrollBehavior() {
        return {x: 0, y: 0}
    }
});

router.beforeEach((to, from, next) => {
    const t = Vue.prototype.vuetify.framework.lang.t(`$vuetify.view_${to.name}`)
    const pageTitle = t == "" ? Vue.prototype.vuetify.framework.lang.t(`$vuetify.view_${to.name}`) : " | " + Vue.prototype.vuetify.framework.lang.t(`$vuetify.view_${to.name}`)
    document.title = window.location.host.split(".")[0].toUpperCase() + " - CertySign" + pageTitle

    if (to.name == null) {
        next(ROOT.PATH)
        return
    }

    let auth = store.getters[AUTHENTICATION_NAMESPACE + "/" + AUTHENTICATION_GETTERS.G_LOGIN_DATA]

    //Si requiere auth y no logado
    if (to.matched.some(record => record.meta.requiresAuth) && !auth[const_global.USER_LOGGED]) {
        next(LOGIN.PATH)
        return

        //Si no requiere auth y está logado
    } else if (!to.matched.some(record => record.meta.requiresAuth) && auth[const_global.USER_LOGGED]) {
        if (to.name == ACTIVATE_USER.NAME || to.name == NEW_PASSWORD.NAME) {
            userLogout().then(() => {
                next()
            })
        } else if (auth[const_global.ACTIVE_SUBACCOUNT][const_global.SUB_ACCOUNT_CODE] == "") {
            next(WITHOUT_SUBACCOUNTS.PATH)
        } else {
            next(ROOT.PATH)
        }
        return

        //Si no tiene subcuentas asignadas y no es una ruta de administración
    } else if (to.matched.some(record => record.meta.subAccountDepends) && auth[const_global.ACTIVE_SUBACCOUNT][const_global.SUB_ACCOUNT_CODE] == "") {
        next(WITHOUT_SUBACCOUNTS.PATH)
        return
        //Si requiere auth, logado y sin permisos
    } else if (to.matched.some(record => record.meta.requiresAuth) && auth[const_global.USER_LOGGED] &&
        !checkPermission(const_global.SECTION + "_" + to.name) &&
        to.name != ROOT.NAME
        && to.name != FORBIDDEN.NAME
    ) {
        next(FORBIDDEN.PATH)
        return
    } else {
        //Controlamos que haya un cuadro de confirmación al abandonar un launch
        if (from.path === '/processes/newtransaction' && to.path !== '/processes/newtransaction' && localStorage.getItem('pl.t') != null) {
            if (!confirm('Si abandona ahora el proceso, perderá los datos introducidos. ¿Está seguro de que desea salir?')) {
                next(false)
                return
            }
        }
        next()
        return
    }

})

export default router;
