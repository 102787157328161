import Vue from 'vue'
import {read, utils} from "xlsx"
import const_icons from "@/constants/icons"
import const_global from "@/constants/global"

const jschardet = require("jschardet")

export const getInitials = text => {
    const exploded = text.split(" ")
    if (exploded.length > 1) {
        return exploded[0][0] + exploded[1][0]
    }
    return exploded[0][0] + exploded[0][1]
}

export const capitalize = value => {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export const getObjectWithoutReactivity = object => {
    return JSON.parse(JSON.stringify(object))
}

export const filterPermissions = permissionArray => {
    let perms = []
    permissionArray.forEach(function (permission) {
        if (permission.assigned) {
            perms.push(permission.key)
        }
    });
    return perms
}

export const allowed_dates_calendar = (dates, val) => {
    if (dates.length === 2) {
        return true;
    } else if (dates.length === 1) {
        const selectDate = new Date(dates[0]);
        const dayToCheck = new Date(val);

        const millisecondsInDay = 24 * 60 * 60 * 1000; // Cantidad de milisegundos en un día
        const millisecondsInYear = 90 * millisecondsInDay; // Cantidad de milisegundos en un año

        const timeDifference = Math.abs(selectDate - dayToCheck);

        return timeDifference < millisecondsInYear;
    } else if (dates.length === 0) {
        return true;
    }
    return false;
}

export const filterProcesses = subAccountsArray => {
    const processes = []
    subAccountsArray.forEach(function (subaccount) {
        if (subaccount.assigned) {
            if (subaccount.processes != null) {
                subaccount.processes.forEach(function (process) {
                    if (process.assigned) {
                        processes.push(process.code)
                    }
                })
            }
        }
    })
    return processes
}

export const filterSubaccounts = subAccountsArray => {
    const subAccounts = []
    subAccountsArray.forEach(function (subaccount) {
        if (subaccount.assigned && subaccount.processes != null && subaccount.processes.length > 0) {
            subAccounts.push(subaccount.sub_account_code)
        }
    })
    return subAccounts
}

export const storageMessage = (message, show) => {
    if (show) {
        console.log(message)
    }
}

export const generatePdfDownload = (fileContent, fileName) => {
    const downloadLink = document.createElement('a')
    document.body.appendChild(downloadLink);

    downloadLink.href = `data:application/pdf;base64,${fileContent}`
    downloadLink.target = '_self'
    downloadLink.download = fileName
    downloadLink.click()
}

export const generateFileDownload = (fileContent, fileName, fileType) => {
    const downloadLink = document.createElement('a')
    document.body.appendChild(downloadLink);

    downloadLink.href = `data:${fileType};base64,${fileContent}`
    downloadLink.target = '_self'
    downloadLink.download = fileName
    downloadLink.click()
}

export const documentIsGivenExtension = (filename, extension) => {
    if (filename && extension) {
        if (filename !== "" && extension !== "") {
            const splitted = filename.toLowerCase().split(".")
            return splitted[splitted.length - 1] === extension.toLowerCase()
        }
    }

    return false
}
/**
 * Función que devuelve si una variable dada está vacia, null, undefined o el array.length = 0
 * @param val
 * @returns {boolean}
 */
export const nullOrEmpty = (val) => {
    return val == null || val === '' || val === undefined || val.length === 0
}

export const readFileContentAsync = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () => resolve(btoa(reader.result))
        reader.onerror = reject
        reader.readAsBinaryString(file)
    })
}

export const readFileContentAsyncAsText = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsText(file)
    })
}

export const fileToBase64String = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}

export const createFileObjectFromBase64 = (file) => {
    const bstr = atob(file.fileContent)
    let n = bstr.length
    const u8array = new Uint8Array(n)

    while (n--) {
        u8array[n] = bstr.charCodeAt(n)
    }

    return new File([u8array], file.fileName, {type: file.fileType})
}

export const convertExcelFileToCSV = async (excelFile) => {
    const data = await excelFile.arrayBuffer()
    const ws = read(data)
    const sheet = ws.Sheets[ws.SheetNames[0]]

    return utils.sheet_to_csv(sheet, {FS: ";"})
}

export const randomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`

export const convertBytesToMegabytes = (bytes) => {
    if (bytes) {
        const mb = bytes * 0.000001
        const splitted = mb.toString().split(".")
        if (splitted.length === 2) {
            return parseFloat(`${splitted[0]}.${splitted[1].slice(0, 2)}`)
        }

        return parseFloat(mb.toString())
    }

    return null
}
export const convertBytesToGigabytes = (bytes) => {
    if (bytes) {
        const gb = bytes * 0.000000001
        const splitted = gb.toString().split(".")
        if (splitted.length === 2) {
            return parseFloat(`${splitted[0]}.${splitted[1].slice(0, 2)}`)
        }

        return parseFloat(gb.toString())
    }

    return null
}

export const showToast = (error, message) => {
    if (message === 500) {
        message = Vue.prototype.vuetify.framework.lang.t('$vuetify.error500Message')
    } else if (message === 401) {
        message = Vue.prototype.vuetify.framework.lang.t('$vuetify.error401Message')
    }

    Vue.$toast(message, {
        position: "bottom-center",
        timeout: error ? const_global.TIME_ALERT_MESSAGE_POPUP_ERROR : const_global.TIME_ALERT_MESSAGE_POPUP_OK,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        hideProgressBar: true,
        closeButton: false,
        icon: {
            iconClass: 'material-icons',
            iconChildren: error ? const_icons.ERROR : const_icons.CHECK,
        },
        rtl: false,
        toastClassName: "b2b-alert"
    })
}

export const createInlineWorker = (fn, args) => {
    const workerFnString = `
    self.onmessage = function(event) {
      (${fn.toString()})(...event.data)
    }
  `

    const blob = new Blob([workerFnString], {type: 'text/javascript'})
    const url = URL.createObjectURL(blob)

    const worker = new Worker(url)
    worker.postMessage(args)

    return worker
}

export const getFileEncoding = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => resolve(jschardet.detect(e.target.result.toString()).encoding)
        reader.onerror = reject
        reader.readAsBinaryString(file)
    })
}

export const convertFileObjectToBackendObject = async (files) => {
    if (!Array.isArray(files)) {
        files = [files]
    }

    const res = await Promise.all(files.map(async x => {
        return {
            fileName: x.name,
            fileContent: await readFileContentAsync(x)
        }
    }))

    return res.length === 1 ? res[0] : res
}

export const fileSizeBase64 = (file) => {
    return (file.size * const_global.SIZE_FACTOR_BASE64_FILE) + file.size
}
export default {
    getInitials,
    capitalize,
    getObjectWithoutReactivity,
    filterPermissions,
    allowed_dates_calendar,
    filterProcesses,
    filterSubaccounts,
    storageMessage,
    generatePdfDownload,
    documentIsGivenExtension,
    nullOrEmpty,
    randomColor,
    showToast,
    fileSizeBase64
}
