<template>
  <v-card class="mx-auto my-2 cts-card-transaction hidden-lg-and-up cts-w-100" max-width="95%" outlined
          @click="openTransaction(item.id)">
    <v-list-item class="cts-list-item-card px-2 px-sm-2">
      <v-list-item-avatar class="hidden-xs-only">
        <v-avatar class="cts-process-card-icon ml-2 cts-bgcolor-clear" size="30" tile>
          <icon-base :icon-name="item.process_name" :src="item.process_image"
                     height="30"
                     icon-color="var(--primary-alternative)" width="30"></icon-base>
        </v-avatar>

      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <v-row class="ma-0 justify-space-between" dense>
            <v-col class="pa-0 ma-0 hidden-sm-and-up" cols="12">
              <v-row class="ma-0 pa-0" dense>
                <v-col class="ma-0 pa-0" cols="12">
                  <v-row dense justify="end">

                    <span class="cts-font-size-5 font-weight-medium cts-white-space pr-1"
                          style="    vertical-align: super;">
                      {{dayjs.unix(item.created_at).format(const_global.DATE_TIME_FORMAT_A)}}
                    </span>
                  </v-row>
                  <h4 class="cts-font-size-5 font-weight-bold cts-white-space pa-1">

                    <icon-base :icon-name="item.process_name" :src="item.process_image"
                               class="mx-0 my-1 mr-2"
                               height="16" icon-color="var(--primary-alternative)"
                               width="16"></icon-base>
                    <span class="font-weight-medium cts-font-size-3"
                          style="vertical-align: super">{{item.transaction_name}}</span>


                  </h4>
                  <h4 class="cts-font-size-5 cts-white-space cts-color-tertiary cts-color-darken pl-1 pb-2 mb-2"
                      style="border-bottom: 1px solid var(--card-border)">
                  <span v-if="item.contact_id" class="cts-font-size-5 font-weight-bold cts-white-space px-1"
                  > {{item.contact_complete_name}} </span>

                    <span v-else-if="allIntervenersFormatted.length !== 0">
                      <span v-if="allIntervenersFormatted[0].id"
                            class="cts-font-size-5 font-weight-bold cts-white-space">{{allIntervenersFormatted[0].id}} </span>
                      <span v-if="allIntervenersFormatted[0].name"> {{allIntervenersFormatted[0].name}}</span>
                      <span v-if="allIntervenersFormatted.length > 1"
                            class="ml-2"> (+{{allIntervenersFormatted.length - 1}})</span>
                    </span>
                  </h4>
                </v-col>
              </v-row>
              <v-row class="ma-0 hidden-sm-and-up justify-space-between" dense>
                <v-col class="px-1" cols="12">
                  <span :style="item.id.indexOf('INVALID') === -1 ? 'color: var(--tertiary) !important;' : 'color: var(--error) !important;'"
                        class="cts-font-size-5 cts-white-space pa-0">{{item.state}}</span>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="pa-0 ma-0 hidden-xs-only" cols="8">
              <h4 class="cts-font-size-5 font-weight-bold cts-white-space cts-color-primary-alternative">{{
                  item.process_name
                }}</h4>
              <span v-if="item.contact_id">

                <span class="cts-font-size-5 font-weight-bold cts-white-space">{{item.contact_id}} </span>
                {{" - " + item.contact_complete_name}}
                </span>
              <span v-else-if="allIntervenersFormatted.length !== 0">
                <span v-if="allIntervenersFormatted[0].id"
                      class="cts-font-size-5 font-weight-bold cts-white-space">{{allIntervenersFormatted[0].id}} </span>
                <span v-if="allIntervenersFormatted[0].name"> {{allIntervenersFormatted[0].name}}</span>
                <span v-if="allIntervenersFormatted.length > 1"
                      class="ml-2"> (+{{allIntervenersFormatted.length - 1}})</span>
              </span>

            </v-col>

            <v-col class="pa-0 ma-0 hidden-xs-only" cols="4">
              <h4 class="cts-font-size-5 font-weight-bold cts-white-space float-right">
                {{$vuetify.lang.t('$vuetify.createdDate')}}:
                {{dayjs.unix(item.created_at).format(const_global.DATE_TIME_FORMAT_A)}}
              </h4>
            </v-col>
          </v-row>
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-row class="ma-0 justify-space-between hidden-xs-only" dense>
            <h4 v-if="item.id"
                class="cts-color-tertiary cts-transaction-datatable-contact-name cts-font-size-5 ">{{
                item.id.split(".").pop()
              }}</h4>
            <h4 v-else
                class="cts-color-tertiary cts-transaction-datatable-contact-name cts-font-size-5 ">
              {{$vuetify.lang.t('$vuetify.transactionWithoutId')}}</h4>
            <span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <h4
                    :style="item.id.indexOf('INVALID') === -1 ? 'color: var(--tertiary) !important;' : 'color: var(--error) !important;'"
                    class="cts-color-tertiary  font-weight-bold cts-font-size-5"
                    v-bind="attrs"
                    v-on="on">{{item.state}}</h4>
                </template>
                <span>{{item.state}}</span>
              </v-tooltip>
            </span>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>

    </v-list-item>
  </v-card>
</template>

<script>
import {getInitials} from "@/util/utils"
import const_global from "@/constants/global"
import IconBase from "../../../assets/images/IconBase"
import const_permissions from "@/constants/permissions"
import {checkPermission} from "@/util/store_utils"

export default {
  name: "transactionsListRowMobile",
  components: {IconBase},
  props: {
    item: Object
  },
  data() {
    return {
      const_global,
      getInitials
    }
  },
  computed: {
    allIntervenersFormatted() {
      let array = []

      if (this.item.contact_id !== '' && this.item.contact_id !== undefined) {
        array = [{
          id: this.item.contact_id,
          name: this.item.contact_complete_name
        }]
      }
      if (this.item.interveners.length > 1 || this.item.interveners.length === 1 && this.item.contact_id != this.item.interveners[0].personal_info.identification_document.document_code) {
        array = array.concat(this.item.interveners.map(intervener => {
          return {
            id: intervener.personal_info.identification_document.document_code,
            name: intervener.personal_info.name
          }
        }))
      }
      if (array.length == 0) {
        array = [{
          id: null,
          name: 'No disponible'
        }]
      }
      return array

    }
  },
  methods: {
    openTransaction(id) {

      if (checkPermission(const_permissions.SECTION_TRANSACTION)) {
        this.$emit('clicked', id)
      }
    },


  }
}
</script>

<style scoped>

</style>
