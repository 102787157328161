export default Object.freeze({

    QUEUED: {
        name: "queued",
        icon: ""
    },
    NEW_COMMUNICATION: {
        name: "new_communication",
        icon: "double_arrow"
    },
    ORCHESTRATOR_NEW_COMMUNICATIONS_COMMUNICATION_RECEIVED: {
        name: "orchestrator_new_communications_communication_received",
        icon: "mdi-check"
    },
    COMMSTRACKER_GENERATE_URL_GENERATED: {
        name: "commstracker_generate_url_generated",
        icon: ""
    },
    COMMSTRACKER_GENERATE_URL_CLICK: {
        name: "commstracker_generate_url_click",
        icon: ""
    },
    COMMUNICATION_SEND_SMS_SENT: {
        name: "communication_send_sms_sent",
        icon: "message"
    },
    COMMUNICATION_SEND_SMS_NOT_SENT: {
        name: "communication_send_sms_not_sent",
        icon: "message"
    },
    COMMUNICATION_SEND_SMS_DELIVERED: {
        name: "communication_send_sms_delivered",
        icon: "message"
    },
    COMMUNICATION_SEND_SMS_NOT_DELIVERED: {
        name: "communication_send_sms_not_delivered",
        icon: "message"
    },
    GENERATE_OTP_CODE_OTP_GENERATED: {
        name: "generate_otp_code_otp_generated",
        icon: ""
    },
    GENERATE_CERTIFICATE_GENERATED: {
        name: "generate_certificate_generated",
        icon: "description"
    },
    COMMUNICATION_SEND_EMAIL_DELIVERED: {
        name: "communication_send_email_delivered",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_COMPLAINT: {
        name: "communication_send_email_complaint",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_QUEUED: {
        name: "communication_send_email_queued",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_DEFERRED: {
        name: "communication_send_email_deferred",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_BOUNCE: {
        name: "communication_send_email_bounce",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_SENT: {
        name: "communication_send_email_sent",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_SENDER_NOT_ALLOWED: {
        name: "communication_send_email_sender_not_allowed",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_OPEN: {
        name: "communication_send_email_open",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_CLICK: {
        name: "communication_send_email_click",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_BLOCK: {
        name: "communication_send_email_block",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_UNSUBSCRIBE: {
        name: "communication_send_email_unsubscribe",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_SPAM: {
        name: "communication_send_email_spam",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_HARD_BLACKLISTED: {
        name: "communication_send_email_hard_blacklisted",
        icon: "email"
    },
    COMMUNICATION_SEND_EMAIL_COMPLAINT_BLACKLISTED: {
        name: "communication_send_email_complaint_blacklisted",
        icon: "email"
    },
    DOCUMENT_UPLOAD_S3_UPLOADED: {
        name: "document_upload_s3_uploaded",
        icon: "cloud_upload"
    },
    COMMUNICATION_SEND_TELEGRAM_DELIVERED: {
        name: "communication_send_telegram_delivered",
        icon: ""
    },
    COMMUNICATION_SEND_TELEGRAM_NOT_DELIVERED: {
        name: "communication_send_telegram_not_delivered",
        icon: ""
    },
    WEB_FRAMEWORK_GENERATED: {
        name: "web_framework_generated",
        icon: ""
    },
    WEB_FRAMEWORK_SOLICITUD_OTP: {
        name: "web_framework_solicitud_otp",
        icon: ""
    },
    WEB_FRAMEWORK_SIGNABLE_DOCUMENT_LOADED: {
        name: "web_framework_signable_document_loaded",
        icon: ""
    },
    WEB_FRAMEWORK_OTP_SIMPLE_SIGNATURE_OK: {
        name: "web_framework_otp_simple_signature_ok",
        icon: ""
    },
    WEB_FRAMEWORK_COMMITED: {
        name: "web_framework_commited",
        icon: ""
    },
    WEB_FRAMEWORK_DOCUMENT_SIGNED: {
        name: "web_framework_document_signed",
        icon: ""
    },
    WEB_FRAMEWORK_FORM_BASED_IDENTIFICATION: {
        name: "web_framework_form_based_identification",
        icon: ""
    },
    WEB_FRAMEWORK_CERTIFICATE_BASED_IDENTIFICATION: {
        name: "web_framework_certificate_based_identification",
        icon: ""
    },
    WEB_FRAMEWORK_DOCUMENT_BASED_IDENTIFICATION: {
        name: "web_framework_document_based_identification",
        icon: ""
    },
    WEB_FRAMEWORK_CERTIFICATE_BASED_IDENTIFICATION_FAILED: {
        name: "web_framework_certificate_based_identification_failed",
        icon: ""
    },
    WEB_FRAMEWORK_DOCUMENT_BASED_IDENTIFICATION_FAILED: {
        name: "web_framework_document_based_identification_failed",
        icon: ""
    },
    WEB_FRAMEWORK_FORM_BASED_IDENTIFICATION_FAILED: {
        name: "web_framework_document_based_identification_failed",
        icon: ""
    },
    WEB_FRAMEWORK_WEB_FRAMEWORK_EXPIRED: {
        name: "web_framework_web_framework_expired",
        icon: ""
    },
    WEB_FRAMEWORK_DOCUMENTATION_DOWNLOADED: {
        name: "web_framework_documentation_downloaded",
        icon: ""
    },
    WEB_FRAMEWORK_ELECTRONIC_NOTIFICACION_REJECTED: {
        name: "web_framework_electronic_notificacion_rejected",
        icon: ""
    },
    WEB_FRAMEWORK_GDPR_FORMULARY_COMMITTED: {
        name: "web_framework_gdpr_formulary_committed",
        icon: ""
    },
    WEB_FRAMEWORK_SEPA_FORMULARY_COMMITTED: {
        name: "web_framework_sepa_formulary_committed",
        icon: ""
    },
    WEB_FRAMEWORK_REQUESTED_OTP_CODE: {
        name: "web_framework_requested_otp_code",
        icon: ""
    },
    WEB_FRAMEWORK_ENTERED_WRONG_OTP_CODE: {
        name: "web_framework_entered_wrong_otp_code",
        icon: ""
    },
    WEB_FRAMEWORK_ENTERED_TIMEDOUT_OTP_CODE: {
        name: "web_framework_entered_timedout_otp_code",
        icon: ""
    },
    WEB_FRAMEWORK_ENTERED_CORRECT_OTP_CODE: {
        name: "web_framework_entered_correct_otp_code",
        icon: ""
    },
    WEB_FRAMEWORK_ELECTRONIC_NOTIFICACION_ACCEPTED: {
        name: "web_framework_electronic_notificacion_accepted",
        icon: ""
    },
    WEB_FRAMEWORK_SIGNATURE_REJECTED: {
        name: "web_framework_signature_rejected",
        icon: ""
    },
    WEB_FRAMEWORK_REQUEST_OTP_CODE_TOO_EARLY: {
        name: "web_framework_request_otp_code_too_early",
        icon: ""
    },
    WEB_FRAMEWORK_CAPTURE_DATA_COMMITED_NO_RESPONSE: {
        name: "web_framework_capture_data_commited_no_response",
        icon: ""
    },
    WEB_FRAMEWORK_CAPTURE_DATA_COMMITED: {
        name: "web_framework_capture_data_commited",
        icon: ""
    },
    WEB_FRAMEWORK_DOCUMENT_PREVIEWED: {
        name: "web_framework_document_previewed",
        icon: ""
    },
    WEB_FRAMEWORK_DOCUMENT_SIMPLY_SIGNED: {
        name: "web_framework_document_simply_signed",
        icon: ""
    },
    DOCUMENT_UPLOAD_ALASTRIA_UPLOADED: {
        name: "document_upload_alastria_uploaded",
        icon: ""
    },
    PUBLISH_DOWNLOADABLE_FILE_GENERATED: {
        name: "publish_downloadable_file_generated",
        icon: ""
    },
    PUBLISH_DOWNLOADABLE_FILE_OPEN: {
        name: "publish_downloadable_file_open",
        icon: ""
    },
    DOCUMENT_SIGN_SIGNED: {
        name: "document_sign_signed",
        icon: ""
    },
    RPC_CALLBACK_PUBLISHED: {
        name: "rpc_callback_published",
        icon: ""
    },
    LOOP_ADD_ADDED: {
        name: "loop_add_added",
        icon: ""
    },
    LOOP_ADD_TICK: {
        name: "loop_add_tick",
        icon: ""
    },
    LOOP_ADD_TIMEOUT: {
        name: "loop_add_timeout",
        icon: ""
    },
    LOOP_CANCEL_CANCELED: {
        name: "loop_cancel_canceled",
        icon: ""
    },
    IDENTIFICATION_CREATE_IDENTIFICATION_GENERATED: {
        name: "identification_create_identification_generated",
        icon: ""
    },
    IDENTIFICATION_CREATE_IDENTIFICATION_OK: {
        name: "identification_create_identification_ok",
        icon: ""
    },
    TEST_LOAD_PROCESSED: {
        name: "test_load_processed",
        icon: ""
    },
    INSTANCE_DELETE_DELETED: {
        name: "instance_delete_deleted",
        icon: ""
    },
    UNZIP_FILE_EXTRACTED: {
        name: "unzip_file_extracted",
        icon: ""
    },
    COMMUNICALIA_CONSENT_ACCEPTED: {
        name: "communicalia_consent_accepted",
        icon: ""
    },
    COMMUNICALIA_CONSENT_DECLINED: {
        name: "communicalia_consent_declined",
        icon: ""
    },
    COMMUNICALIA_CONSENT_NO_DATA: {
        name: "communicalia_consent_no_data",
        icon: ""
    },
    ROBINSON_LIST_ACCEPTED: {
        name: "robinson_list_accepted",
        icon: ""
    },
    ROBINSON_LIST_DECLINED: {
        name: "robinson_list_declined",
        icon: ""
    },
    ROBINSON_LIST_NO_DATA: {
        name: "robinson_list_no_data",
        icon: ""
    },
    PUBLISH_EMBEDDABLE_FILE_GENERATED: {
        name: "publish_embeddable_file_generated",
        icon: ""
    },
    DOCUMENT_UPLOAD_SFTP_UPLOADED: {
        name: "document_upload_sftp_uploaded",
        icon: ""
    },
    FILE_DELETE_DELETED: {
        name: "file_delete_deleted",
        icon: ""
    },
    DISABLE_FRAMEWORK_WEB_FRAMEWORK_WEB_DISABLED: {
        name: "disable_framework_web_framework_web_disabled",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSACTION_CREATE: {
        name: "generate_onespan_transaction_transaction_create",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSACTION_CREATE_ERROR: {
        name: "generate_onespan_transaction_transaction_create_error",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSACTION_COMPLETE: {
        name: "generate_onespan_transaction_transaction_complete",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSACTION_EXPIRE: {
        name: "generate_onespan_transaction_transaction_expire",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSACTION_DECLINE: {
        name: "generate_onespan_transaction_transaction_decline",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_EMAIL_BOUNCE: {
        name: "generate_onespan_transaction_email_bounce",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSACTION_OPT_OUT: {
        name: "generate_onespan_transaction_transaction_opt_out",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSACTION_DELETE: {
        name: "generate_onespan_transaction_transaction_delete",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSATION_DESACTIVATE: {
        name: "generate_onespan_transaction_transation_desactivate",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_KBA_FAILURE: {
        name: "generate_onespan_transaction_kba_failure",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSACTION_SENT: {
        name: "generate_onespan_transaction_transaction_sent",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_TRANSACTION_SENT_ERROR: {
        name: "generate_onespan_transaction_transaction_sent_error",
        icon: ""
    },
    GENERATE_ONESPAN_TRANSACTION_PACKAGE_TRASH: {
        name: "generate_onespan_transaction_package_trash",
        icon: ""
    },
    GET_ONESPAN_FILES_FILES_ONESPAN_GENERATED: {
        name: "get_onespan_files_files_onespan_generated",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_GENERATED: {
        name: "communication_send_postal_generated",
        icon: "description"
    },
    COMMUNICATION_SEND_POSTAL_SENT_TO_FACTORY: {
        name: "communication_send_postal_sent_to_factory",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_PRINTED: {
        name: "communication_send_postal_printed",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_PRE_ADMISSION_REQUESTED: {
        name: "communication_send_postal_pre_admission_requested",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_PRE_ADMITTED: {
        name: "communication_send_postal_pre_admitted",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_SENT_TO_LOGISTIC_OPERATOR: {
        name: "communication_send_postal_sent_to_logistic_operator",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_ON_THE_WAY_TO_LOGISTIC_OPERATOR: {
        name: "communication_send_postal_on_the_way_to_logistic_operator",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_RECEIVED_BY_LOGISTIC_OPERATOR: {
        name: "communication_send_postal_received_by_logistic_operator",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_NOT_RECEIVED_BY_LOGISTIC_OPERATOR: {
        name: "communication_send_postal_not_received_by_logistic_operator",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_ON_DELIVERY: {
        name: "communication_send_postal_on_delivery",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_DELIVERED_TO_RECIPIENT: {
        name: "communication_send_postal_delivered_to_recipient",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_RETURNED_TO_POSTAL_OFFICE: {
        name: "communication_send_postal_returned_to_postal_office",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_DELIVERED_INTO_POSTAL_OFFICE: {
        name: "communication_send_postal_delivered_into_postal_office",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_GENERIC_DELIVERED: {
        name: "communication_send_postal_generic_delivered",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_RETURNED: {
        name: "communication_send_postal_returned",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_CANCELED: {
        name: "communication_send_postal_canceled",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_LOST: {
        name: "communication_send_postal_lost",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_EXPIRED: {
        name: "communication_send_postal_expired",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_REJECTED: {
        name: "communication_send_postal_rejected",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_STOLEN: {
        name: "communication_send_postal_stolen",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_OUTSIDE_SICER: {
        name: "communication_send_postal_outside_sicer",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_BAD_POSTAL_ADDRESS: {
        name: "communication_send_postal_bad_postal_address",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_PASSED_AWAY: {
        name: "communication_send_postal_passed_away",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_OTHER: {
        name: "communication_send_postal_other",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_NO_INFORMATION: {
        name: "communication_send_postal_no_information",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_RECEIVED_PEE: {
        name: "communication_send_postal_received_pee",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_CERTIFICATE_RETURNED: {
        name: "communication_send_postal_certificate_returned",
        icon: ""
    },
    COMMUNICATION_SEND_POSTAL_UNKNOWN: {
        name: "communication_send_postal_unknown",
        icon: ""
    },
    ZIP_FILES_PATH_ZIPPED: {
        name: "zip_files_path_zipped",
        icon: ""
    },
    INTROMISSION_API_RETRIES_MUTED: {
        name: "intromission_api_retries_muted",
        icon: ""
    },
    INTROMISSION_API_RETRIES_UNMUTED: {
        name: "intromission_api_retries_unmuted",
        icon: ""
    },
    INTROMISSION_API_INSTANCE_CANCELED: {
        name: "intromission_api_instance_canceled",
        icon: ""
    },
    ANTIVIRUS_FILTERED: {
        name: "antivirus_filtered",
        icon: ""
    }
});
