<template>
  <v-expand-transition>
    <v-dialog v-model="open" max-width="600px" @click:outside="closeModal()">
      <v-card tile>
        <v-card-title class="col-lg-12 pa-2">
          <dialog-title :text="$vuetify.lang.t('$vuetify.eventsInfo')" icon="import_contacts"/>
        </v-card-title>
        <v-card-text class="py-8">
          <v-row v-for="event in eventsFiltered" :key="event.id" class="col-lg-12 mx-auto d-block"
                 justify="center">
            <h2>{{event.event | capitalize}}</h2>
            <span>
                        {{$vuetify.lang.t('$vuetify.' + const_events[formatEventKey(event.key)].name)}}

</span>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="cts-button-standard my-2" color="var(--primary-alternative)" dark
                 small @click="closeModal()">
            {{$vuetify.lang.t('$vuetify.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expand-transition>
</template>

<script>
import DialogTitle from "../../structures/dialogTitle";
import {ServerBus} from "@/main";
import const_events from "@/constants/events";
import {capitalize} from "@/util/utils";

export default {
  name: "TransactionEventsDetails",
  components: {DialogTitle},
  props: {
    events: Array
  },
  data() {
    return {
      open: true,
      const_events
    }
  },
  computed: {
    eventsFiltered() {
      return this.events.filter(event => const_events[this.formatEventKey(event.key)]).sort((a, b) => {
        return const_events[this.formatEventKey(a.key)].timestamp - const_events[this.formatEventKey(b.key)].timestamp
      })
    }

  },
  methods: {
    closeModal() {
      this.open = false
      this.$emit('close')
      ServerBus.$emit("close", true)
    },
    formatEventKey(key) {
      return key.replace(".", "_").toUpperCase()
    },
  },
  filters: {
    capitalize
  }
}
</script>
