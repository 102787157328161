<script>
import {getDocumentBase64} from "@/services/otherServices";
import {generateFileDownload} from "@/util/utils";


export default {
  name: "TransactionFilesList",
  props: {
    files: [Array, null]
  },
  data() {
    return {
      downloadLoading: false
    }
  },
  methods: {

    getDocumentName(documentKey) {
      return documentKey.substring(documentKey.lastIndexOf('/') + 1)
    },
    documentImgSrc(documentType) {
      if (documentType.toLowerCase() === 'pdf') {
        return require('@/assets/images/icons/pdf.png')
      }

      if (documentType.toLowerCase() === 'html') {
        return require('@/assets/images/icons/html.png')
      }

      return require('@/assets/images/icons/other-docs.png')
      // return documentType.toLowerCase() === 'pdf' ? require('@/assets/images/icons/pdf.png') : require('@/assets/images/icons/html.png')
    },
    async handleClickDocument(document) {
      this.downloadLoading = true

      let fileObject

      fileObject = await getDocumentBase64(document.key)
      fileObject = fileObject.data


      generateFileDownload(fileObject.file_content, fileObject.file_name, document.type)

      this.downloadLoading = false
    }


  }

}
</script>

<template>
  <v-col v-if="files" class="pa-0">
    <v-row v-for="file in files" :key="file.key" dense>
      <v-col class="pa-0" cols="12">
        <v-list-item class="pa-0 transaction-file-card px-2">
          <v-list-item-avatar>
            <v-img :src="documentImgSrc(file.type)" aspect-ratio="1/1" cover
                   width="20"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{getDocumentName(file.key)}}</v-list-item-title>
            <v-list-item-subtitle>{{file.type}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn :loading="downloadLoading" color="var(--primary-alternative)" icon
                   @click="handleClickDocument(file)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-col>
    </v-row>
  </v-col>
  <v-col v-else>
    <span>{{$vuetify.lang.t('$vuetify.withoutFiles')}}</span>
  </v-col>
</template>

<style lang="scss">
.transaction-file-card {
  border: solid 1px var(--card-border);
  border-radius: 4px;

}
</style>