export const buildInterveners = (interveners, contact_channel, orderEnabled) => {
  const interveners_CS = [];
  const tags_SMS = []
  const tags_email = []

  for (const intervener of interveners) {
    const contact_info = {
      email: [intervener.contact_info.email],
      phone: {
        phone_number: intervener.contact_info.phone.phone_number,
        country_code: intervener.contact_info.phone.country_code
      }
    }

    const personal_info = {
      name: intervener.personal_info.name,
      identification_document: intervener.personal_info.identification_document
    }

    const tag = !intervener.id_type
      ? `${intervener.extra_info.identification_type}_${contact_channel}`
      : `DOC_${intervener.extra_info.contact_channel}`;

    const files = { signature_documents: "%%documents%%" };
    const metadata = { custom_message: intervener.metadata.custom_message };

    if (intervener.extra_info.contact_channel === "email"){
      tags_email.push(tag);

      files.email_body = "PlantillaCorreo.html"
      files.email_body_reminder = "PlantillaCorreoRecordatorio.html"

      metadata.email_subject = "%%PROCESS.email_subject%%";

    } else {
      tags_SMS.push(tag)
      metadata.push ({ sms_text: "%%PROCESS.sms_text%%" })
    }

    interveners_CS.push({
      contact_info,
      personal_info,
      tag,
      id: intervener.extra_info.id,
      order: orderEnabled ? intervener.order : 1,
      files,
      metadata
    })

    // if(intervener.extra_info.contact_channel === "email"){
    //   tags_email.push(tag)
    // } else {
    //   tags_SMS.push(tag)
    // }
  }

  return {
    interveners: interveners_CS,
    tags_SMS,
    tags_email
  };
}

export const buildCommunications = (reminders, interveners) => {
  const communication_email_CS = [];
  const communication_SMS_CS = [];

  for (const intervener of interveners) {
    const channel = intervener.extra_info.contact_channel;
    const communication = { time: "0 minutes", step: channel.toUpperCase() };

    (channel === "email" ? communication_email_CS : communication_SMS_CS).push(communication);

    for (const reminder of reminders) {
      const reminderCommunication = {
        time: `${reminder.days} days`,
        step: `${channel.toUpperCase()}_REMINDER`
      };

      (channel === "email" ? communication_email_CS : communication_SMS_CS).push(reminderCommunication);
    }
  }

  return { communication_email_CS, communication_SMS_CS };
}

export const buildSignatures = (signatures) => {
  const newSignatures = [];

  for (const signatureObject of signatures) {
    const newAnnotations = signatureObject.annotations.map(signature => {
      const position = {
        x: Math.round(signature.position.x) /*/ Math.round(parseFloat(signature.scale))*/,
        y: Math.round(signature.position.y) /*/ Math.round(parseFloat(signature.scale))*/,
        width: Math.round(signature.position.width) /*/ Math.round(parseFloat(signature.scale))*/,
        height: Math.round(signature.position.height) /*/ Math.round(parseFloat(signature.scale))*/,
        page: signature.position.page
      }
      return {
        intervener_id: signature.intervener_id,
        position,
        settings: signature.settings,
        type: signature.type
      }
    });


    const newSignature = {
      document_name: signatureObject.document_name,
      annotations: newAnnotations
    };

    newSignatures.push(newSignature);
  }

  return newSignatures;
}

export const buildSettings = (interveners, contact_channel) => {
  const front_renderer_settings = [];

  for (const intervener of interveners) {
    const setting = {
      apply_to_tag: !intervener.id_type
        ? `${intervener.extra_info.identification_type}_${contact_channel}`
        : `DOC_${intervener.extra_info.contact_channel}`,

      models: {
        "skeleton": "model-1",
        "form-identification": "model-1",
        "otp-identification": "model-1",
        "certificate-identification": "model-1",
        "reliable-identification": "model-1",
        "document": "model-1",
        "intervener_finished": "model-1",
        "transaction_finished": "model-1",
        "transaction_canceled": "model-1",
        "in_use": "model-1",
        "waiting_other_interveners": "model-1",
        "identification-document-image": "model-1",
        "transaction_rejected": "model-1",
        "otp-notification": "model-1",
      },

      allow_rejection: false,
      allow_delegation: false,
      allow_exceed_number_of_otp: false,
      number_of_otps: 5,
      max_number_of_otps: 10,
      otp_contact_preferences: [ intervener.extra_info.contact_channel ],
      otp_validity_seconds: 600,
      otp_regeneration_seconds: 600,
      pdf_render_version: 2,
      prepend_pages: [],
      require_scroll_to_bottom: false,
      related_documents_tag: "signature_documents",
      signature_popup: true,
      single_click_signature: false,
      supervisor: false,
      write_rejection_reason: true
    };

    switch (intervener.extra_info.identification_type?.toLowerCase()) {
      case "nif":
      case "dni":
        setting.identifications = [{
          "type": "form-identification",
          "field": "document_code",
          "expiration_seconds": 3000
        }];
        break;

      case "email_otp":
      case "otp":
        setting.identifications = [{
          "type": "otp-identification",
          "field": "otp_code",
          "expiration_seconds": 3000
        }];
        break;

      case "cert":
        setting.identifications = [{
          "type": "cert-identification",
          "field": "document_code",
          "expiration_seconds": 3000
        }]
        break;

      case "ama":
        setting.identifications = [{
          "type": "ama-identification",
          "field": "document_code",
          "expiration_seconds": 3000
        }]
        break;
    }

    front_renderer_settings.push(setting);
  }

  return front_renderer_settings
}
