<template>
    <v-container class="cts-main-container cts-main-view col-12 pa-0 pt-2">
        <v-scroll-y-transition>
            <v-col class="ma-0 pa-0 " cols="12">
                <v-row class="ma-0" dense>
                    <v-col class="col-lg-offset-1 mx-auto cts-view-header" cols="11" lg="10" md="11" sm="11" xl="10">
                        <v-row dense>
                            <v-col cols="12" lg="10" md="10" sm="10" xl="10">
                                <view-title :icon="const_icons.SUPPORT"
                                            :subtitle="$vuetify.lang.t('$vuetify.supportDescription')"
                                            :title="$vuetify.lang.t('$vuetify.selectHelpsTooltip')"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pb-2 pt-8" dense>
                    <v-col class="ma-o pa-0 pb-2" cols="12">
                        <v-row class="justify-center" dense>
                            <v-col class="pl-4 pb-2 cts-font-size-4 cts-color-tertiary" cols="11" lg="10" md="11"
                                   xl="10"
                                   xs="11">
                                <v-row class="justify-space-between" dense>
                                    <v-col class="pl-4 pb-2 cts-font-size-4 cts-color-tertiary" cols="12" lg="6" md="6"
                                           xl="6"
                                           xs="6">
                                        <section-title :icon="const_icons.CONTACT_PHONE"
                                                       :text="$vuetify.lang.t('$vuetify.helpSupport')"
                                                       idName="support"></section-title>
                                        <v-row class="pt-4" dense>
                                            <v-col cols="12">
                                                <v-form ref="form" v-model="valid"
                                                        class="cts-modal-form"
                                                        lazy-validation
                                                >
                                                    <v-row class="ma-0 pa-0" dense>
                                                        <v-col cols="12">
                                                            <v-row class="py-0 px-2">
                                                                <v-col class="ma-0 pa-0" cols="12">
                                                                    <v-row dense>
                                                                        <v-col cols="11">
                                                                            <h2 id="support-text-contact_form_title"
                                                                                class="cts-subtitle-recovery font-weight-regular cts-font-size-4 hidden-xs-only">
                                                                                {{
                                                                                    $vuetify.lang.t('$vuetify.rememberPasswordSupportWebText')
                                                                                }}</h2>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row dense>
                                                                        <v-col>
                                                                            <h3
                                                                              v-if="supportConfig.show_hours_email_support"
                                                                              id="support-text-schedule_m_to_f"
                                                                              class="mt-1 cts-font-size-5">
                                                                                <strong>{{
                                                                                        $vuetify.lang.t('$vuetify.rememberPasswordMondayToFriday')
                                                                                    }} </strong>
                                                                                8:00h - 22:00h</h3>
                                                                            <h3
                                                                              v-if="supportConfig.show_hours_email_support"
                                                                              id="support-text-schedule_sat_and_sun"
                                                                              class="cts-font-size-5">
                                                                                <strong>{{$vuetify.lang.t('$vuetify.rememberPasswordSaturdaySundayAndOthers')}} </strong>
                                                                                10:00h - 22:00h
                                                                            </h3>
                                                                            <h3
                                                                              v-if="supportConfig.show_hours_telephone_support"
                                                                              id="support-text-schedule_phone_support"
                                                                              class="cts-font-size-5">
                                                                                <strong>{{
                                                                                        $vuetify.lang.t('$vuetify.helpSupportPhoneSchedule')
                                                                                    }}: </strong>
                                                                                {{const_global.WORKING_DAY_START_TIME}}:00h
                                                                                -
                                                                                {{const_global.WORKING_DAY_END_TIME}}:00h
                                                                            </h3>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>

                                                        <v-col v-if="isSupportTime" class="pt-4" cols="12" sm="7">
                                                            <v-row v-if="isWorkHours" class="pb-3 pt-1 px-2" dense>
                                                                <v-checkbox
                                                                  id="support-input-checkbox_help_support_phone_preffered"
                                                                  v-model="phonePreferred"
                                                                  aria-label="checkbox-active-user"
                                                                  class="cts-tabs-checkboxes cts-modal-checkbox pa-0 py-1 ma-0"
                                                                  color="var(--primary)"
                                                                />
                                                                <h3
                                                                  id="support-input-checkbox_help_support_phone_preffered_phrase"
                                                                  class="mt-0 cts-color-tertiary py-0"
                                                                  style="line-height: 32px"
                                                                >
                                                                    {{
                                                                        $vuetify.lang.t('$vuetify.helpSupportPhonePreffered')
                                                                    }}
                                                                </h3>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col v-if="phonePreferred" cols="12" sm="5">
                                                            <v-text-field
                                                              v-model="input.phone"
                                                              :placeholder="$vuetify.lang.t('$vuetify.telephone')"
                                                              :prepend-inner-icon="const_icons.TELEPHONE"
                                                              :rules="phoneRules"
                                                              autocomplete="on"
                                                              class="support-phone-preferred__input"
                                                              name="phone"
                                                              outlined
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                    <v-row :class="!isSupportTime ? 'mt-2' : ''" class="ma-0 pa-0"
                                                           dense>
                                                        <v-col cols="12">
                                                            <v-textarea id="support-input-message_textbox"
                                                                        v-model="input.message"
                                                                        :placeholder="$vuetify.lang.t('$vuetify.messageDescription')"
                                                                        :rules="textareaRules" aria-required="true"
                                                                        name="message"
                                                                        outlined
                                                                        prepend-inner-icon="article"></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="ma-0 pa-0" dense>
                                                        <v-col cols="12">
                                                            <v-expand-transition>
                                                                <v-row v-if="errorMessage != null"
                                                                       class="ma-0 cts-login-error-message"
                                                                       dense>
                                                                    <span class="caption">{{errorMessage}}</span>
                                                                </v-row>
                                                            </v-expand-transition>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="ma-0 pa-0" dense>
                                                        <v-col class="mx-auto" cols="12" sm="6">
                                                            <v-btn
                                                              id="support-button-sendMessage"
                                                              :loading="supportCalling"
                                                              class="my-0 cts-button-login"
                                                              @click="sendSupportMessage()"
                                                            >
                                                                {{$vuetify.lang.t('$vuetify.sendRecoveryForm')}}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                      v-if="supportConfig.pdf_manual || supportConfig.pdf_guide"
                                      class="pr-4 pb-2 cts-font-size-4 cts-color-tertiary"
                                      cols="12"
                                      lg="4"
                                      md="4"
                                      xl="4"
                                      xs="4"
                                    >
                                        <section-title
                                          :icon="const_icons.MANUAL"
                                          :text="manualGuideSectionTitle"
                                          idName="support"
                                        />
                                        <v-row dense>
                                            <v-col>
                                                <p id="support-text-support_manual_description">
                                                    {{$vuetify.lang.t('$vuetify.supportManualDescription')}}</p>
                                                <v-row class="mb-2 mt-4 mx-0 justify-space-between" dense>
                                                    <v-btn v-if="supportConfig.pdf_manual"
                                                           class="cts-button-standard" role="menuitem"
                                                           small @click="openModal('manual_pdf')">
                                                        {{$vuetify.lang.t('$vuetify.helpManual')}}
                                                        <v-icon class="float-right" small>{{
                                                                const_icons.MANUAL
                                                            }}
                                                        </v-icon>
                                                    </v-btn>
                                                </v-row>
                                                <v-row class="mb-2 mt-4 mx-0 justify-space-between" dense>
                                                    <v-btn v-if="supportConfig.pdf_guide" class="cts-button-standard"
                                                           role="menuitem" small @click="openModal('guia_pdf')">
                                                        {{$vuetify.lang.t('$vuetify.helpGuide')}}
                                                        <v-icon class="float-right" small>{{
                                                                const_icons.MANUAL
                                                            }}
                                                        </v-icon>
                                                    </v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-scroll-y-transition>
        <v-dialog v-model="open_dialog" max-width="700px" @click:outside="closeModal">
            <v-card tile>
                <v-card-title class="col-lg-12 pa-2">
                    <dialog-title
                      v-if="dialogMode === 'manual_pdf'"
                      :close="true"
                      :icon="const_icons.MANUAL"
                      :text="$vuetify.lang.t('$vuetify.helpManual')"
                      :vuex_dialog_action="vuex_dialog_action"
                      @close="closeModal"
                    />

                    <dialog-title
                      v-if="dialogMode === 'guia_pdf'"
                      :close="true"
                      :icon="const_icons.MANUAL"
                      :text="$vuetify.lang.t('$vuetify.helpGuide')"
                      :vuex_dialog_action="vuex_dialog_action"
                      @close="closeModal"
                    />
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-row>
                        <p-d-f-viewer :file="currentFile"></p-d-f-viewer>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import const_icons from '@/constants/icons'
import const_global from '@/constants/global'
import ViewTitle from "../components/structures/viewTitle"
import SectionTitle from "../components/sections/processes/processView/sectionTitle"
import {
    NAMESPACE as FUNCTIONALITY_NAMESPACE,
    STATE as FUNCTIONALITY_STATE,
    ACTIONS as FUNCTIONALITY_ACTIONS
} from "@/constants/vuex/functionality"
import {
    NAMESPACE as AUTHENTICATION_NAMESPACE,
    GETTERS as AUTHENTICATION_GETTERS,
    ACTIONS as AUTHENTICATION_ACTIONS
} from "@/constants/vuex/authentication"
import {mapGetters} from "vuex"
import DialogTitle from "../components/structures/dialogTitle"
import PDFViewer from "../components/structures/PDFViewer"
import {showToast} from "@/util/utils"
import {launchSupportMessage} from "@/services/supportServices"
import {getManualPDF, getGuiaPDF} from '@/services/otherServices'
import {NAMESPACE as LOCATION_NAMESPACE} from "@/constants/vuex/location"
import {NAMESPACE as GLOBAL_NAMESPACE, GETTERS as GLOBAL_GETTERS} from "@/constants/vuex/global"
import dayjs from "dayjs"

export default {
    name: "Support",
    components: {PDFViewer, DialogTitle, SectionTitle, ViewTitle},
    data: function () {
        return {
            const_global,
            now: null,
            const_icons,
            vuex_dialog_action: null,
            valid: true,
            dialogMode: null,
            theme: null,
            subdomain: window.location.host.split(".")[0].toLowerCase(),
            errorMessage: null,
            authObject: null,
            supportCalling: false,
            finalSendMessage: null,
            phonePreferred: false,
            input: {},
            pdfBase64: null,
            textareaRules: [
                v => !!v || this.$vuetify.lang.t('$vuetify.requiredTextarea')],
            phoneRules: [
                v => !!v || this.$vuetify.lang.t('$vuetify.requiredPhone'),
                v => !v || const_global.PHONE_REGEX.test(v) || this.$vuetify.lang.t('$vuetify.requiredPhoneFormat')
            ],

        }
    },
    created() {
        // this.now = new Date()
        this.now = dayjs()
        this.authObject = this.$store.getters[`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_GETTERS.G_LOGIN_DATA}`]

        let host = window.location.host.split(".")[0].toLowerCase()

        let htmlElement = document.documentElement
        this.$store.dispatch('appearance/changeTheme', host)
        localStorage.setItem("theme", host)
        htmlElement.setAttribute('theme', host)
        this.theme = host

        this.closeModal()

        if (this.mobile) {
            this.vuex_dialog_action = FUNCTIONALITY_ACTIONS.A_SET_ALERT_SUPPORT_DIALOG_M
        } else {
            this.vuex_dialog_action = FUNCTIONALITY_ACTIONS.A_SET_ALERT_SUPPORT_DIALOG_D
        }
    },
    computed: {
        ...mapGetters({
            appearance: 'appearance/getAppearance',
            supportConfig: `${GLOBAL_NAMESPACE}/${GLOBAL_GETTERS.GET_SUBDOMAIN_CONFIG}`
        }),

        currentFile() {
            return this.pdfBase64
        },

        isWorkHours() {
            return this.now.get('hour') >= const_global.WORKING_DAY_START_TIME && this.now.get('hour') < const_global.WORKING_DAY_END_TIME
        },

        open_dialog: {
            get() {
                let val = null
                if (this.mobile) {
                    val = this.$store.state[FUNCTIONALITY_NAMESPACE][FUNCTIONALITY_STATE.S_ALERT_SUPPORT_DIALOG_M]
                } else {
                    val = this.$store.state[FUNCTIONALITY_NAMESPACE][FUNCTIONALITY_STATE.S_ALERT_SUPPORT_DIALOG_D]
                }
                if (!val && (this.phone)) {
                    this.closeModal()
                }
                return val
            },
            set(value) {
                if (this.mobile) {
                    this.$store.dispatch(FUNCTIONALITY_NAMESPACE + "/" + FUNCTIONALITY_ACTIONS.A_SET_ALERT_SUPPORT_DIALOG_M, value)
                } else {
                    this.$store.dispatch(FUNCTIONALITY_NAMESPACE + "/" + FUNCTIONALITY_ACTIONS.A_SET_ALERT_SUPPORT_DIALOG_D, value)
                }

            }
        },
        lang() {
            return this.$store.state[LOCATION_NAMESPACE]["currentLocation"]["code"].toUpperCase()
        },
        isSupportTime() {
            return const_global.WORKING_WEEK_DAYS.includes(this.now.get("day")) && this.isWorkHours
        },

        manualGuideSectionTitle() {
            if (this.supportConfig.pdf_manual && this.supportConfig.pdf_guide) {
                return this.$vuetify.lang.t('$vuetify.supportManualTitle')
            }

            if (this.supportConfig.pdf_manual) {
                return this.$vuetify.lang.t('$vuetify.supportManualTitleOnlyManual')
            }

            return this.$vuetify.lang.t('$vuetify.supportManualTitleOnlyGuide')

        }
    },
    methods: {
        openModal(support) {
            const subdomain = (this.subdomain + '_' + this.$store.state[LOCATION_NAMESPACE]['currentLocation']['code'])
            this.dialogMode = support
            if (support === "manual_pdf") {
                this.pdfBase64 = null
                this.open_dialog = true
                getManualPDF(subdomain)
                  .then(response => {
                      this.pdfBase64 = {
                          name: `${this.$vuetify.lang.t('$vuetify.manual')}.pdf`,
                          fileContent: response.data
                      }
                  })
                  .catch(error => {
                      showToast(error, this.$vuetify.lang.t(`$vuetify.${error.message}`))
                  })


            }
            if (support === "guia_pdf") {
                this.pdfBase64 = null
                this.open_dialog = true
                getGuiaPDF(subdomain)
                  .then(response => {
                      this.pdfBase64 = {
                          name: `${this.$vuetify.lang.t('$vuetify.guia')}.pdf`,
                          fileContent: response.data
                      }
                  })
                  .catch(error => {
                      showToast(error, this.$vuetify.lang.t(`$vuetify.${error.message}`))
                  })
            }
        },
        closeModal() {
            this.$store.dispatch(FUNCTIONALITY_NAMESPACE + "/" + FUNCTIONALITY_ACTIONS.A_SET_ALERT_SUPPORT_DIALOG_D, false)
            this.$store.dispatch(FUNCTIONALITY_NAMESPACE + "/" + FUNCTIONALITY_ACTIONS.A_SET_ALERT_SUPPORT_DIALOG_M, false)

            this.vuex_dialog_action = null
            this.pdfBase64 = null
            this.open_dialog = false
        },
        sendSupportMessage() {
            this.errorMessage = null
            this.supportCalling = true

            if (this.$refs.form.validate()) {

                let support_message = "[Soporte de usuario ya logado][" + this.lang + "]"

                this.phonePreferred ? support_message += "[Preferencia telefónica]" : support_message += "[Preferencia email]"

                let data = {
                    name: this.authObject.name,
                    email: this.authObject.username,
                    phone: this.phonePreferred ? this.input.phone : "No especificado",
                    username: this.authObject.username,
                    company: this.subdomain.toUpperCase(),
                    message: support_message + "\n" + this.input.message
                }

                launchSupportMessage(data, 'help', this.phonePreferred).then(() => {
                    this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_CHECKING_LOGIN}`, false)
                    this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_REMEMBER_SEND}`, true)
                })

                setTimeout(() => {

                    let error = false
                    this.finalSendMessage = this.$vuetify.lang.t('$vuetify.supportFinalSendMessage')
                    this.phonePreferred = false

                    showToast(error, this.finalSendMessage)
                    this.input = {}
                    this.$refs.form.resetValidation()
                    this.supportCalling = false
                }, 1000)

            } else {
                this.errorMessage = this.$vuetify.lang.t('$vuetify.toContinueFillFields')
                this.supportCalling = false
                this.valid = false
            }
        }
    }
}
</script>

<style lang="scss">
.support-phone-preferred__input {
    .v-text-field__details {
        display: block !important;
    }
}
</style>